export const validations = {
    descripcion: {
        required: {
            value: true,
            message: "Debe ingresar una descripción de la compra",
        }
    },
    numeroCuotas: {
        required: {
            value: true,
            message: "Debe seleccionar la cantidad de cuotas",
        },
    },
    fecha: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de compra",
        },
    },
    valor: {
        required: {
            value: true,
            message: "Debe ingresar un monto de la compra",
        },
        pattern: {
            value: /^(?!(0\.?0*)$)(\d+|\d*\.\d\d?)$/,
            message: "El monto debe ser mayor a cero",
        },
    },
}