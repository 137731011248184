export const findByParam = (data = [] as any, param = '') => {
    if (param.length === 0) {
        return data
    }

    let newList = [] as any

    for (let i = 0; i < data.length; i++) {

        const obj = {
            tipo: data[i].tipo,
            valor: data[i].valor,
        }

        const objValues = Object.values(obj)

        if (JSON.stringify(objValues).toLowerCase().includes(param.toLowerCase())) {
            newList = [
                ...newList,
                data[i]
            ]
        }
    }

    return newList
}