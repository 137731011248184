import {CustomHeading} from "../../../components/CustomHeading";
import {useFetch} from "../../../hooks/useFetch";
import {
    Badge,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import {FaPenAlt, FaTrash} from "react-icons/fa";
import {Categoria, Comercio} from "../../../interfaces/Comercio";
import {findByParam} from "../../../helpers/findByParam";
import {useEffect, useRef, useState} from "react";
import {DeleteConfirm} from "../../../components/DeleteConfirm";
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {api} from "../../../api/api";
import {ListAlertDialog} from "../../../components/ListAlertDialog";
import {TableSkeleton} from "../../../components/TableSkeleton";

export const Categorias = () => {
    const {data, isLoading} = useFetch("/categorias/all")
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const exportXLSPath = "/categorias/export_xls"
    const toast = useCustomToast({})
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef: any = useRef()
    const [listAlertInfo, setListAlertInfo] = useState({list , description: ''} as any)
    const [isListAlertOpen, setIsListAlertOpen] = useState(false)
    const [infoToDelete, setInfoToDelete] = useState({id: '', description: ''})

    const handleDeleteCategoria = async (id: string) => {
        try {
            const res = await api.get(`comercios/by_categoria/${id}`)

            if (res.data != null) {
                setListAlertInfo({list: [...res.data], description: infoToDelete.description})
                onClose()
                setIsListAlertOpen(true)
            } else {
                await deleteObjectByID("categorias", id)

                setList(list.filter((value: Comercio) => value.id !== id))
                setInitialList(initialList.filter((value: Comercio) => value.id !== id))
                toast({title: "Categoría eliminada con éxito!", status: "success"})
                onClose()
            }
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            toast({title: "La categoría no pudo ser eliminada!", status: "error"})
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    return (
        <>
            <CustomHeading title={'Categorías'}
                           addBtnTo={'/categorias/nuevo'}
                           data={list}
                           findParam={handleFindParam}
                           path={exportXLSPath}/>

            <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Nombre</Th>
                            <Th>Fecha Alta</Th>
                            <Th maxWidth={'3rem'}>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list ?
                            list.map((categoria: Categoria) => (
                                <Tr key={categoria.id}>
                                    <Td><Badge variant='subtle' colorScheme={'cyan'}> {categoria.nombre}</Badge></Td>
                                    <Td>{format(parseISO(categoria.fechaAlta), 'dd-MM-yyyy')}</Td>
                                    <Td maxWidth={'3rem'}>
                                        <Link to={`/categorias/editar/${categoria.id}`}>
                                            <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                        aria-label={`editar ${categoria.id}`} icon={<FaPenAlt/>}/>
                                        </Link>
                                        <IconButton size={'sm'} onClick={() => {
                                            onOpen()
                                            setInfoToDelete({id: categoria.id, description: categoria.nombre})
                                        }}
                                                    mx={1}
                                                    colorScheme={'red'} aria-label={`borrar ${categoria.id}`}
                                                    icon={<FaTrash/>}/>
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <TableSkeleton isLoading={isLoading}/>
            <HStack justify={'space-around'} my={4}>
                <Badge colorScheme={'purple'}>Categorias totales: {list ? list.length : 0} </Badge>
            </HStack>

            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'la'}
                           entity={'categoría'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDeleteCategoria}
                           cancelRef={cancelRef}
            />

            <ListAlertDialog isOpen={isListAlertOpen}
                             onClose={() => setIsListAlertOpen(false)}
                             pron={'La'}
                             entity={'categoría'}
                             list={listAlertInfo.list}
                             description={listAlertInfo.description}
                             cancelRef={cancelRef}
            />
        </>
    );
}