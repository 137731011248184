import {FaArrowLeft} from "react-icons/fa";
import {Button} from "@chakra-ui/react";
import {Link} from "react-router-dom";

export const BackButton = ({pathTo}: any) => {
    return (
        <Link to={pathTo}>
            <Button leftIcon={<FaArrowLeft/>} colorScheme={'gray'}>
                Volver
            </Button>
        </Link>
    )
}