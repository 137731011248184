import {
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Image,
    Input,
    NumberInput,
    NumberInputField,
    Select,
    Stack,
    VStack,
    Wrap
} from "@chakra-ui/react";

import {useEffect, useRef, useState} from "react";
import {FaCloudUploadAlt, FaTrash} from "react-icons/fa";
import {useForm} from "../../../hooks/useForm";
import {useNavigate, useParams} from "react-router-dom";
import {format, formatISO, parse, parseISO} from 'date-fns'
import {validations} from "../helpers/validations";
import {useCustomToast} from "../../../hooks/useCustomToast";
import noShopImage from "../../../assets/img/no_building.png";
import {Categoria, Comercio} from "../../../interfaces/Comercio";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {SaveButton} from "../../../components/SaveButton";
import {api} from "../../../api/api";
import {useFetchCategorias} from "../helpers/getCategorias";
import {errorCodeAlreadyCreated, errorCodeEmailAlreadyRegistered} from "../../../utils/constants";
import {getQuotesNumber} from "../../../helpers/getQuotesNumber";
import {BackButton} from "../../../components/BackButton";


export const FormComercio = () => {
    const {id} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const nameRef = useRef([] as any);
    const contRef = useRef([] as any);
    const telRef = useRef([] as any);
    const [selectedFile, setSelectedFile] = useState({inputName: '', file: ''} as any)
    const hoy = new Date().toISOString().slice(0, 10)
    const [maxQuoteSelected, setMaxQuoteSelected] = useState(0)
    const photoInputRef = useRef([] as any);
    const {object}: any =
        useGetObjectByID('comercios/get', id, 'el', 'comercio')

    const [localidades] = useState(['USHUAIA', 'TOLHUIN', 'RIO GRANDE'])
    const {categorias} = useFetchCategorias()

    const {form, onInputChange, setFormState, errors} = useForm({validations, fechaAlta: hoy})

    useEffect(() => {
        if (object.id) {
            object.fechaAlta = format(parseISO(object.fechaAlta), 'yyyy-MM-dd')
            object.categoria = JSON.stringify(object.categoria)
            setFormState({...object, validations})
            setMaxQuoteSelected(object.maximoCuotas)
            return
        }
        setMaxQuoteSelected(1)
    }, [object]);

    const handlePhotoUpload = () => {
        photoInputRef.current.click()
    }

    const handlePhotoDelete = () => {
        setFormState({
            ...form,
            foto: {
                id: '',
                url: ''
            }
        })
    }

    const onFileInputChange = async ({target}: any) => {
        const file = target.files[0]
        const name = file.name

        const reader = new FileReader()
        reader.onload = ({target}: any) => {
            setSelectedFile({inputName: name, file: file})
            setFormState({
                ...form,
                foto: {
                    id: form.foto ? form.foto.id : '',
                    url: target.result
                }
            })
        }
        reader.readAsDataURL(file)
    }

    const handleOnChangeMaxQuote = ({target}: any) => {
        const {value} = target

        setMaxQuoteSelected(value)

        setFormState({
            ...form,
            maximoCuotas: value
        })
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (errors.length !== 0) {
            return
        }

        setIsSaving(true)

        let action = 'save'
        let status = 'guardado'
        if (id) {
            action = 'update'
            status = 'actualizado'
        }

        let foto
        if (selectedFile.file) {

            const formData = new FormData()

            formData.append('fotoId', form.foto.id)
            formData.append('file', selectedFile.file)
            formData.append('group', 'comercios')

            try {
                foto = await api.post(`/fotos/upload`, formData)

            } catch (error: any) {
                if (error.response &&
                    error.response.status === 403) {
                    if (!toast.isActive("custom-toast")) {
                        toast({
                            id: "custom-toast",
                            title: `Sesión cerrada por inactividad`,
                            status: 'info'
                        })
                    }
                    return
                }

                setIsSaving(false)
                toast({title: `El comercio no pudo ser ${status}!`, status: 'error'})
                return
            }
        }

        let comercio: Comercio = {...form}

        comercio.nombre = comercio.nombre.trim()
        comercio.correo = comercio.correo.trim()
        comercio.direccion = comercio.direccion.trim()
        comercio.contacto = comercio.contacto.trim()
        comercio.maximoCuotas = Number(maxQuoteSelected)

        comercio.fechaAlta = formatISO(parse(form.fechaAlta, 'yyyy-MM-dd', new Date()))
        comercio.cuit = Number(form.cuit)
        comercio.telefono = Number(form.telefono)

        if (foto) {
            comercio.foto = foto.data
        }

        comercio.usuario = {
            ...comercio.usuario,
            nombreUsuario: comercio.usuario.nombreUsuario,
        }

        comercio.categoria = JSON.parse(form.categoria)

        try {
            await api.post(`/comercios/${action}`, comercio)
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeAlreadyCreated) {
                setIsSaving(false)
                if (!toast.isActive("custom-toast")) {
                    toast({title: `El comercio con cuit ${comercio.cuit} ya existe!`, status: 'error'})
                }

                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeEmailAlreadyRegistered) {
                setIsSaving(false)
                toast({
                    title: `Ya existe un registro guardado con el correo electronico ${comercio.correo}!`,
                    status: 'error'
                })

                return
            }

            setIsSaving(false)
            if (!toast.isActive("custom-toast")) {
                toast({title: `El comercio no pudo ser ${status}!`, status: 'error'})
            }
        }

        if (!toast.isActive("custom-toast")) {
            toast({title: `Comercio ${status} correctamente!`, status: 'success'})
        }

        navigate("/comercios", {replace: true})
    }

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading>{id ? 'Editar comercio' : 'Nuevo comercio'}</Heading>
                <HStack>
                    <BackButton pathTo={'/comercios'}/>
                </HStack>
            </HStack>
            <Divider my={5}/>

            <form autoComplete={'off'}>
                <Stack height={'60vh'} overflowY={'scroll'}>

                    {
                        <VStack
                            mb={1}
                            justifyContent={"space-around"}
                        >
                            <Image
                                boxShadow={'2xl'}
                                borderRadius={'full'}
                                boxSize={'170px'}
                                objectFit={'cover'}
                                src={form.foto && form.foto.url}
                                fallbackSrc={noShopImage}
                            />
                            <FormControl display={"none"}>
                                <Input ref={photoInputRef}
                                       type={'file'}
                                       name={'file'}
                                       onChange={onFileInputChange}
                                       accept="image/*"
                                />
                            </FormControl>
                            <HStack pt={3}>
                                <Button onClick={handlePhotoUpload} leftIcon={<FaCloudUploadAlt/>}
                                        colorScheme={"teal"}
                                        size={"sm"}>Subir</Button>
                                <Button onClick={handlePhotoDelete} leftIcon={<FaTrash/>} colorScheme={"red"}
                                        size={"sm"}>Borrar</Button>
                            </HStack>
                        </VStack>

                    }

                    <HStack justify={"space-around"} mb={4}>
                        <VStack width={'30%'}>
                            <FormControl isInvalid={submitted && errors.nombre} isRequired>
                                <FormLabel>Nombre</FormLabel>
                                <Input
                                    ref={nameRef}
                                    autoComplete={'false'}
                                    name={'nombre'}
                                    placeholder={"Ingrese un nombre"}
                                    type='text'
                                    value={form.nombre || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.nombre ? (
                                    <FormErrorMessage>{errors.nombre}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.cuit} isRequired>
                                <FormLabel>Cuit</FormLabel>
                                <NumberInput
                                    name={'cuit'}
                                    value={form.cuit || []}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={"Ingrese un cuit. ej: 33445566778"}/>
                                </NumberInput>
                                {submitted && errors.cuit ? (
                                    <FormErrorMessage> {errors.cuit}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.contacto} isRequired>
                                <FormLabel>Contacto</FormLabel>
                                <Input
                                    ref={contRef}
                                    name={'contacto'}
                                    placeholder={"Ingrese un contacto"}
                                    type='text'
                                    value={form.contacto || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.contacto ? (
                                    <FormErrorMessage>{errors.contacto}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.fechaAlta} isRequired>
                                <FormLabel>Fecha de alta</FormLabel>
                                <Input
                                    readOnly={true}
                                    name={'fechaAlta'}
                                    type='date'
                                    value={form.fechaAlta || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.fechaAlta ? (
                                    <FormErrorMessage>{errors.fechaAlta}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>

                        </VStack>
                        <VStack width={'30%'}>
                            <FormControl isInvalid={submitted && errors.telefono} isRequired>
                                <FormLabel>Teléfono</FormLabel>
                                <NumberInput
                                    name={'telefono'}
                                    value={form.telefono || []}
                                >
                                    <NumberInputField ref={telRef}
                                                      onChange={onInputChange}
                                                      placeholder={"Ingrese un teléfono. ej: 2901334455"}
                                                      autoComplete={'off'}
                                    />
                                </NumberInput>

                                {submitted && errors.telefono ? (
                                    <FormErrorMessage>{errors.telefono}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.direccion} isRequired>
                                <FormLabel>Dirección</FormLabel>
                                <Input
                                    name={'direccion'}
                                    placeholder={"Ingrese una dirección"}
                                    type='text'
                                    value={form.direccion || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.direccion ? (
                                    <FormErrorMessage>{errors.direccion}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.localidad} isRequired>
                                <FormLabel>Localidad</FormLabel>
                                <Select name={'localidad'}
                                        placeholder='Seleccione una localidad'
                                        value={form.localidad}
                                        onChange={onInputChange}
                                >
                                    {localidades ?
                                        localidades.map((localidad: string, i) => (
                                            <option key={i} value={localidad}>{localidad}</option>
                                        )) : []
                                    }
                                </Select>
                                {submitted && errors.localidad ? (
                                    <FormErrorMessage>{errors.localidad}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.categoria} isRequired>
                                <FormLabel>Categoria</FormLabel>
                                <Select name={'categoria'}
                                        placeholder='Seleccione una categoria'
                                        value={form.categoria}
                                        onChange={onInputChange}
                                >
                                    {categorias ?
                                        categorias.map((cat: Categoria) => (
                                            <option key={cat.id}
                                                    value={JSON.stringify(cat)}>{cat.nombre}</option>
                                        )) : []
                                    }
                                </Select>
                                {submitted && errors.categoria ? (
                                    <FormErrorMessage>{errors.categoria}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>

                        </VStack>
                        <VStack width={'30%'}>
                            <FormControl isInvalid={submitted && errors.correo} isRequired>
                                <FormLabel>Correo</FormLabel>
                                <Input
                                    name={'correo'}
                                    type='email'
                                    placeholder={'Ingrese un correo. ej: localcomercial@mail.com'}
                                    value={form.correo || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.correo ? (
                                    <FormErrorMessage>{errors.correo}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl>
                                <FormLabel>Usuario</FormLabel>
                                <Input
                                    isReadOnly={true}
                                    name={'usuario.nombreUsuario'}
                                    type='text'
                                    placeholder='Usuario generado automáticamente'
                                    value={form.usuario ? form.usuario.nombreUsuario : ''}
                                    onChange={onInputChange}
                                />
                            </FormControl>
                        </VStack>
                    </HStack>
                    <Box>
                        <Divider my={4}/>
                    </Box>
                    <Stack px={4}>
                        <HStack>
                            <FormControl isInvalid={submitted && errors.maximoCuotas} isRequired>
                                <FormLabel>Cantidad máxima de cuotas disponibles</FormLabel>
                                <HStack>
                                    <Wrap>
                                        {
                                            getQuotesNumber().map((value) =>
                                                <Button
                                                    colorScheme={Number(maxQuoteSelected) === value ? 'telegram' : 'gray'}
                                                    key={value}
                                                    value={value}
                                                    onClick={handleOnChangeMaxQuote}>{value}</Button>
                                            )
                                        }
                                    </Wrap>
                                </HStack>

                                {submitted && errors.maximoCuotas ? (
                                    <FormErrorMessage>{errors.maximoCuotas}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </HStack>
                    </Stack>
                </Stack>
                <HStack justify={'flex-end'}>
                    <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                </HStack>
            </form>
        </>
    )
}

// only for dev
const testComercio = {
    nombre: 'Gualdesi',
    cuit: 20353566009,
    contacto: "Pedro Picapiedras",
    direccion: 'mi dir 123',
    foto: null,
    fechaAlta: '2022-09-16',
    localidad: 'Ushuaia',
    correo: 'guald@mail.com',
    habilitado: true,
    telefono: 2901538259,
    usuario: '',
    maximoCuotas: 9,
}