import {
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    ListItem,
    Select,
    Stack,
    UnorderedList,
    VStack
} from "@chakra-ui/react";

import {useEffect, useState} from "react";
import {useForm} from "../../../hooks/useForm";
import {useNavigate, useParams} from "react-router-dom";
import {format, formatISO, parse, parseISO} from 'date-fns'
import {validations, validationsWithoutPassword} from "../helpers/validations";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {Usuario} from "../../../interfaces/Usuario";
import {SaveButton} from "../../../components/SaveButton";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {api} from "../../../api/api";
import {errorCodeAlreadyCreated} from "../../../utils/constants";
import {useSelector} from "react-redux";
import {BackButton} from "../../../components/BackButton";

export const fakePassword = '********'
export const FormUsuario = () => {
    const {username} = useSelector((state: any) => state.store)
    const {id} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [roles] = useState(['USUARIO', 'ADMIN'])
    const [rolAsignado, setRolAsignado] = useState('')
    const today = new Date().toISOString().slice(0, 10)

    const {object}: any =
        useGetObjectByID('usuarios/get', id, 'el', 'usuario')

    const vals = (object.rol === roles[0] || object.rol === roles[1]) ? validations : validationsWithoutPassword

    const {form, onInputChange, setFormState, errors} = useForm(
        { vals, fechaAlta: today}
    )

    const onShowPassword = () => {

        if (showPassword) {
            setShowPassword(false)
            return
        }

        setFormState({
            ...form,
            contrasena: '',
            contrasenaCheck: '',
            validations
        })

        setShowPassword(true)
    }

    useEffect(() => {
        if (object.id) {

            if (username !== 'mfuhr' && object.nombreUsuario === 'mfuhr') {
                navigate("/usuarios", {replace: true})
            }

            object.fechaAlta = format(parseISO(object.fechaAlta), 'yyyy-MM-dd')
            setFormState({
                ...object,
                contrasena: fakePassword,
                contrasenaCheck: fakePassword,
                validations
            })

            if (object.rol === 'SOCIO' || object.rol === 'COMERCIO') {
                setRolAsignado(object.rol)
            }
        }
    }, [object]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (errors.length !== 0) {
            return
        }

        setIsSaving(true)
        let usuario: Usuario = {...form}

        usuario.fechaAlta = formatISO(parse(form.fechaAlta, 'yyyy-MM-dd', new Date()))

        let action = 'save'
        let status = 'guardado'
        if (id) {
            action = 'update'
            status = 'actualizado'
        }

        try {
            await api.post(`/usuarios/${action}`, usuario)

            toast({title: `Usuario ${status} correctamente!`, status: 'success'})
            navigate("/usuarios", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            setIsSaving(false)

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeAlreadyCreated) {
                toast({title: `El escalafón ${usuario.nombreUsuario} ya existe!`, status: 'error'})
                return
            }

            if (error.response &&
                error.response.status !== 200) {
                toast({title: `El usuario no pudo ser ${status}!`, status: 'error'})
                return
            }
        }
    }

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading>{id ? 'Editar usuario' : 'Nuevo usuario'}</Heading>
                <HStack>
                    <BackButton pathTo={'/usuarios'}/>
                </HStack>
            </HStack>
            <Divider my={5}/>

            <form autoComplete={'off'}>
                <Stack height={'60vh'} overflowY={'scroll'}>
                    <HStack justify={"space-around"} mb={4}>
                        <VStack width={'40%'}>
                            <FormControl isInvalid={submitted && errors.nombreUsuario} isRequired>
                                <FormLabel>Nombre de usuario</FormLabel>
                                <Input
                                    name={'nombreUsuario'}
                                    placeholder={"Ingrese un nombre de usuario"}
                                    type='text'
                                    value={form.nombreUsuario || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.nombreUsuario ?
                                    <>
                                        {typeof errors.nombreUsuario !== 'string' ?
                                            errors.nombreUsuario.map((err: any, k: any) => (
                                                <FormErrorMessage>
                                                    <UnorderedList>
                                                        <ListItem key={k}>{err}</ListItem>
                                                    </UnorderedList>
                                                </FormErrorMessage>
                                            )) : <FormErrorMessage>{errors.nombreUsuario}</FormErrorMessage>}
                                    </>
                                    : ([])}
                            </FormControl>
                            {
                                form.rol === roles[0] ||
                                form.rol === roles[1] ?
                                    <>

                                        <FormControl isInvalid={submitted && errors.contrasena} isRequired>
                                            <FormLabel>Contraseña</FormLabel>
                                            <InputGroup>
                                                <Input
                                                    autoComplete={'new-password'}
                                                    pr={'4.5rem'}
                                                    name={'contrasena'}
                                                    placeholder={"Ingrese una contraseña"}
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={form.contrasena || []}
                                                    onChange={onInputChange}
                                                />
                                                <InputRightElement width={'5rem'} pe={'0.5rem'}>
                                                    <Button h='1.75rem' size='sm' onClick={onShowPassword}>
                                                        {showPassword ? 'Ocultar' : 'Mostrar'}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                            {submitted && errors.contrasena ?
                                                <>
                                                    {typeof errors.contrasena !== 'string' ?
                                                        errors.contrasena.map((err: any, k: any) => (
                                                            <FormErrorMessage>
                                                                <UnorderedList>
                                                                    <ListItem key={k}>{err}</ListItem>
                                                                </UnorderedList>
                                                            </FormErrorMessage>
                                                        )) : <FormErrorMessage>{errors.contrasena}</FormErrorMessage>}
                                                </>
                                                : ([])}
                                        </FormControl>
                                        <FormControl isInvalid={submitted && errors.contrasenaCheck} isRequired>
                                            <FormLabel>Repetir contraseña</FormLabel>
                                            <InputGroup>
                                                <Input
                                                    autoComplete={'new-password'}
                                                    name={'contrasenaCheck'}
                                                    placeholder={"Ingrese la contraseña nuevamente"}
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={form.contrasenaCheck || []}
                                                    onChange={onInputChange}
                                                />
                                                <InputRightElement width={'5rem'} pe={'0.5rem'}>
                                                    <Button h='1.75rem' size='sm'
                                                            onClick={onShowPassword}>
                                                        {showPassword ? 'Ocultar' : 'Mostrar'}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                            {submitted && errors.contrasenaCheck ?
                                                <>
                                                    {typeof errors.contrasenaCheck !== 'string' ?
                                                        errors.contrasenaCheck.map((err: any, k: any) => (
                                                            <FormErrorMessage>
                                                                <UnorderedList>
                                                                    <ListItem key={k}>{err}</ListItem>
                                                                </UnorderedList>
                                                            </FormErrorMessage>
                                                        )) :
                                                        <FormErrorMessage>{errors.contrasenaCheck}</FormErrorMessage>}
                                                </>
                                                : ([])}
                                        </FormControl>
                                    </> :
                                    <></>
                            }
                            <FormControl isInvalid={submitted && errors.rol} isRequired>
                                <FormLabel>Rol</FormLabel>
                                {
                                    rolAsignado !== '' ?
                                        <Select name={'rol'}
                                                value={rolAsignado}
                                        >
                                            <option key={1} value={rolAsignado}>{rolAsignado}</option>
                                        </Select>
                                        :
                                        <Select name={'rol'}
                                                placeholder='Seleccione un rol'
                                                value={form.rol}
                                                onChange={onInputChange}
                                        >
                                            {roles ?
                                                roles.map((rol: string, i: number) => (
                                                    <option key={i} value={rol}>{rol}</option>
                                                )) : []
                                            }
                                        </Select>
                                }
                                {submitted && errors.rol ? (
                                    <FormErrorMessage>{errors.rol}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>

                        </VStack>
                        <VStack width={'40%'}>
                            <FormControl isInvalid={submitted && errors.fechaAlta} isRequired>
                                <FormLabel>Fecha de alta</FormLabel>
                                <Input
                                    readOnly={true}
                                    name={'fechaAlta'}
                                    type='date'
                                    value={form.fechaAlta || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.fechaAlta ? (
                                    <FormErrorMessage>{errors.fechaAlta}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </VStack>
                    </HStack>
                </Stack>
                <HStack justify={'flex-end'}>
                    <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                </HStack>
            </form>
        </>
    )
}