export const findByParam = (data = [] as any, param = '') => {
    if (param.length === 0) {
        return data
    }

    param = param.toLowerCase()

    let newList = [] as any

    for (let i = 0; i < data.length; i++) {

        const obj = {
            nombre: data[i].nombre,
            apellido: data[i].apellido,
            numeroDocumento: data[i].numeroDocumento,
            legajo: data[i].legajo!,
            contacto: data[i].contacto,
            cuit: data[i].cuit,
            direccion: data[i].direccion,
            telefono: data[i].telefono,
            correo: data[i].correo,
        }

        // TODO: improve even more


        if (param.includes(' ')) {
            const combinado = (obj.apellido + obj.nombre).replaceAll(' ', '').toLowerCase()
            const combinadoAlt = (obj.nombre + obj.apellido).replaceAll(' ', '').toLowerCase()
            const nombreArr = obj.nombre.split(' ')
            const combinadoCorto = (nombreArr[0] + obj.apellido).toLowerCase()

            const cleanParam = param.replaceAll(' ', '').toLowerCase()

            if (combinado.includes(cleanParam) ||
                combinadoAlt.includes(cleanParam) ||
                combinadoCorto.includes(cleanParam)) {
                newList = [
                    ...newList,
                    data[i]
                ]

                continue
            }
        }

        const objValues = Object.values(obj)

        if (JSON.stringify(objValues).toLowerCase().includes(param)) {
            newList = [
                ...newList,
                data[i]
            ]
        }
    }

    return newList
}