import {
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    VStack
} from "@chakra-ui/react";

import {useEffect, useState} from "react";
import {useForm} from "../../../hooks/useForm";
import {useNavigate, useParams} from "react-router-dom";
import {format, formatISO, parse, parseISO} from 'date-fns'
import {validations} from "../helpers/validations";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {Categoria} from "../../../interfaces/Comercio";
import {api} from "../../../api/api";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {SaveButton} from "../../../components/SaveButton";
import {errorCodeAlreadyCreated} from "../../../utils/constants";
import {BackButton} from "../../../components/BackButton";


export const FormCategoria = () => {
    const {id} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const today = new Date().toISOString().slice(0, 10)
    const {object}: any =
        useGetObjectByID('categorias/get', id, 'la', 'categoría')

    const {form, onInputChange, setFormState, errors} = useForm({validations, fechaAlta: today})

    useEffect(() => {
        if (object.id) {
            object.fechaAlta = format(parseISO(object.fechaAlta), 'yyyy-MM-dd')
            setFormState({...object, validations})
        }
    }, [object]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (errors.length !== 0) {
            return
        }

        setIsSaving(true)

        let categoria: Categoria = {...form}

        categoria.fechaAlta = formatISO(parse(form.fechaAlta, 'yyyy-MM-dd', new Date()))

        let action = 'save'
        let status = 'guardada'
        if (id) {
            action = 'update'
            status = 'actualizada'
        }

        try {
            await api.post(`/categorias/${action}`, categoria)

            toast({title: `Categoría ${status} correctamente!`, status: 'success'})
            navigate("/categorias", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeAlreadyCreated) {
                setIsSaving(false)
                toast({title: `La categoría ${categoria.nombre} ya existe!`, status: 'error'})

                return
            }

            setIsSaving(false)
            toast({title: `La categoría no pudo ser ${status}!`, status: 'error'})
        }
    }

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading>{id ? 'Editar categoría' : 'Nueva categoría'}</Heading>
                <HStack>
                    <BackButton pathTo={'/categorias'}/>
                </HStack>
            </HStack>
            <Divider my={5}/>

            <form>
                <Stack height={'60vh'} overflowY={'scroll'}>
                    <HStack justify={"space-around"} mb={4}>
                        <VStack width={'40%'}>
                            <FormControl isInvalid={submitted && errors.nombre} isRequired>
                                <FormLabel>Nombre</FormLabel>
                                <Input
                                    name={'nombre'}
                                    placeholder={"Ingrese un nombre"}
                                    type='text'
                                    value={form.nombre || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.nombre ? (
                                    <FormErrorMessage>{errors.nombre}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.fechaAlta} isRequired>
                                <FormLabel>Fecha de alta</FormLabel>
                                <Input
                                    readOnly={true}
                                    name={'fechaAlta'}
                                    type='date'
                                    value={form.fechaAlta || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.fechaAlta ? (
                                    <FormErrorMessage>{errors.fechaAlta}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>

                        </VStack>
                        <VStack width={'40%'}>
                        </VStack>
                    </HStack>
                </Stack>
                <HStack justify={'flex-end'}>
                    <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                </HStack>
            </form>
        </>
    )
}

// only for dev
const testCategoria = {
    nombre: 'ALIMENTOS',
    fechaAlta: '',
}