import {api} from "../../../api/api";
import {useEffect, useState} from "react";
import {useCustomToast} from "../../../hooks/useCustomToast";

export const useGetCuotaSocial = () => {
    const toast = useCustomToast({})
    const [cuotaSocialValor, setCuotaSocialValor] = useState({})

    const getData = async () => {
        try {
            const res = await api.get(`/variables/get/CuotaSocial`)
            setCuotaSocialValor(res.data.valor)

        } catch (error: any) {
            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo obtener el valor de la cuota social`, status:'error'})
            }
            setCuotaSocialValor([])
            return
        }

    }

    useEffect(() => {
        getData()
    }, []);

    return {
        cuotaSocialValor
    }
}