import {
    Button,
    Divider,
    Heading,
    Input,
    InputGroup,
    InputLeftElement, Skeleton,
    SkeletonText,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {FaFileExcel, FaSearch} from "react-icons/fa";
import {AddButton} from "../../../components/AddButton";
import {useDownloadFile} from "../../../hooks/useDownloadFile";
import {isComercio} from "../../../store/auth/auth";
import {useSelector} from "react-redux";


export const CustomComprasHeading = ({
                                         title = '',
                                         findParam = [] as any,
                                         month = '',
                                         data = [],
                                         onChangeMonth = [] as any
                                     }) => {

    const {downloadFile, isDownloading} = useDownloadFile({value: data})
    const {rol} = useSelector((state: any) => state.store)

    return (
        <>
            <Wrap justify={'space-between'}>
                <WrapItem>
                    <Heading size='lg'>{title}</Heading>
                </WrapItem>

                <WrapItem>
                    <Wrap>
                        <WrapItem>
                            <InputGroup>
                                <Input
                                    type={'month'}
                                    value={month}
                                    onChange={onChangeMonth}
                                />
                            </InputGroup>
                        </WrapItem>
                        <WrapItem>
                            <InputGroup zIndex={0}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<FaSearch color={'lightgray'}/>}
                                />
                                <Input placeholder={'Buscar'} onChange={findParam}/>
                            </InputGroup>
                        </WrapItem>

                        <WrapItem>
                            <Button px={12}
                                    isLoading={isDownloading}
                                    disabled={data ? data.length === 0 : true}
                                    onClick={() => downloadFile(`/compras/export_xls/${month}/${isComercio(rol)}`)}
                                    colorScheme={'green'}
                                    variant={'solid'} aria-label={'download xls'}
                                    leftIcon={<FaFileExcel/>}>Exportar</Button>
                        </WrapItem>
                        <WrapItem>
                            <AddButton pathTo={'/compras/nuevo'}/>
                        </WrapItem>
                    </Wrap>
                </WrapItem>

            </Wrap>
            <Divider
                my={5}
            />
        </>
    )
}