import {
    Button,
    Center,
    FormControl,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    VStack,
    Wrap
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import favicon from "../assets/img/logo.png";
import {FaEye, FaEyeSlash, FaKey, FaSignInAlt, FaUser} from "react-icons/fa";
import {LoginUser} from "../interfaces/LoginUser";
import {useDispatch} from "react-redux";
import {api} from "../api/api";
import {login} from "../store/storeSlice";
import {useNavigate} from "react-router-dom";
import {useCustomToast} from "../hooks/useCustomToast";
import {welcome} from "../helpers/welcome";

export const Login = () => {
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const [nombreUsuario, setNombreUsuario] = useState('')
    const [contrasena, setContrasena] = useState('')

    const dispach = useDispatch()

    useEffect(() => {
        setIsMobile(false)
        if ( window.innerWidth <= 1000) {
            setIsMobile(true)
        }
    }, [window.innerWidth]);


    const onSubmit = async (event: any) => {
        event.preventDefault();

        setIsLoading(true)

        let loginUser: LoginUser = {
            username: nombreUsuario,
            password: contrasena
        }

        try {
            const res = await api.post('/auth/login', loginUser)
            dispach(login(res.data))
            navigate("/", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 401) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 2000)

                if (!toast.isActive("custom-toast")) {
                    toast({title: `El nombre de usuario o la contraseña son incorrectos.`, status: 'error'})
                }
                return
            }

            setTimeout(() => {
                setIsLoading(false)
            }, 2000)

            if (!toast.isActive("custom-toast")) {
                toast({title: `El sistema no esta disponible temporalmente, intente nuevamente en unos minutos.`, status: 'error'})
            }
        }
    }

    return (
        <>
            <HStack justify={'center'}>

                <Center w={isMobile ? '100%' : '50%'}
                        h={650}
                        borderRadius={20}>
                    <VStack>

                        <Image mb={5} src={favicon} alt='favicon' w={56}/>
                        <Wrap w={isMobile ? '100%' : '70%'}
                              bg={'#027297'}
                              p={10}
                              borderRadius={20}
                              color={'#F5F5F5'}>
                            <Heading size={'lg'}>{welcome()}</Heading>
                            <FormControl pt={5}>
                                <InputGroup>
                                    <InputLeftElement width={'3rem'} py={'1.6rem'}>
                                        <Icon fontSize={isMobile ? 20 : 22} color={'#027297'}> <FaUser/> </Icon>
                                    </InputLeftElement>
                                    <Input
                                        py={6}
                                        fontSize={isMobile ? 'md' : 'lg'}
                                        bg={'#F5F5F5'}
                                        color={'black'}
                                        name={'nombreUsuario'}
                                        placeholder={"Ingrese su usuario"}
                                        type='text'
                                        value={nombreUsuario}
                                        onChange={({target}) => setNombreUsuario(target.value.toLowerCase())}
                                        onKeyDown={(event) => event.key === 'Enter' ? onSubmit(event) : []}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl py={5}>
                                <InputGroup>
                                    <InputLeftElement width={'3rem'} py={'1.6rem'}>
                                        <Icon fontSize={isMobile ? 20 : 22} color={'#027297'}> <FaKey/> </Icon>
                                    </InputLeftElement>
                                    <Input
                                        py={6}
                                        fontSize={isMobile ? 'md' : 'lg'}
                                        bg={'#F5F5F5'}
                                        color={'black'}
                                        name={'contrasena'}
                                        placeholder={"Ingrese la contraseña"}
                                        type={showPassword ? 'text' : 'password'}
                                        value={contrasena}
                                        onChange={({target}) => setContrasena(target.value)}
                                        onKeyDown={(event) => event.key === 'Enter' ? onSubmit(event) : []}
                                    />
                                    <InputRightElement width={'4rem'} py={'1.6rem'}>
                                        <IconButton size={'md'}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    fontSize={isMobile ? 20 : 24}
                                                    color={'#027297'}
                                                    aria-label={'showPassword'}
                                                    icon={!showPassword ? <FaEye/> : <FaEyeSlash/>}/>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Button isLoading={isLoading}
                                    size={'lg'} fontSize={isMobile ? 'lg' : 'xl'}
                                    onClick={onSubmit}
                                    type={"button"}
                                    leftIcon={<FaSignInAlt/>}
                                    colorScheme={'whiteAlpha'}>
                                Entrar
                            </Button>
                        </Wrap>
                        <VStack justifyContent={'space-evenly'}>
                            <Button size={'lg'}
                                    onClick={() => navigate("/signup", {replace: false})}
                                    type={"button"}
                                    colorScheme={'green'}>
                                Generar nueva contraseña
                            </Button>
                            <Button size={'lg'}
                                    onClick={() => navigate("/forgot", {replace: false})}
                                    type={"button"}
                                    colorScheme={'gray'}>
                                Olvidé mi contraseña
                            </Button>
                        </VStack>
                    </VStack>
                </Center>
            </HStack>
        </>
    )
}