import {
    Box,
    Button,
    Center,
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerOverlay, Heading,
    HStack,
    List,
    ListItem,
    Skeleton,
    Stat,
    StatArrow,
    StatHelpText,
    StatLabel,
    StatNumber,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
    VStack,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {CustomHeading} from "../components/CustomHeading";
import {useCustomToast} from "../hooks/useCustomToast";
import {useEffect, useState} from "react";
import {api, statusForbidden, statusInternalServerError} from "../api/api";
import {useSelector} from "react-redux";
import {isAdmin, isUser} from "../store/auth/auth";
import {format} from "date-fns";
import es from "date-fns/locale/es";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import {FaBirthdayCake, FaIdCard, FaPhone, FaRegSmile} from "react-icons/fa";

export const Dashboard = () => {
    const toast = useCustomToast({})

    const [isOpen, setIsOpen] = useState(false)
    const [partners, setPartners] = useState([]);

    const showEventInfo = (data: any) => {

        const elements = Array.from(document.getElementsByClassName('fc-popover'))
        elements.at(0)?.setAttribute("hidden", "true")

        const def = data.event._def
        setPartners(def.extendedProps.partnerEvents)
        setIsOpen(true)
    }

    const [info, setInfo] = useState({
        sociosNum: 0,
        difSocios: 0,
        activos: 0,
        difActivos: 0,
        adherentes: 0,
        difAdherentes: 0,
        comercios: 0,
        difComercios: 0,
        compras: 0,
        difCompras: 0,
        descuentos: 0,
        difDescuentos: 0,
        ingresos: 0,
        difIngresos: 0,
        socios: [],
        events: []
    })
    const [isLoading, setIsLoading] = useState(true)

    const {rol} = useSelector((state: any) => state.store)

    useEffect(() => {
        getDashboardInfo()
    }, []);

    const getDashboardInfo = async () => {
        try {
            const res = await api.get('/info/dashboard')

            setInfo({
                ...res.data,
            })
        } catch (error: any) {
            if (error.response &&
                error.response.status === statusForbidden) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            if (error.response &&
                error.response.status === statusInternalServerError) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Plataforma fuera de servicio`,
                        status: 'warning'
                    })
                }
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo obtener la información del tablero`, status: 'error'})
            }

            setInfo({
                sociosNum: 0,
                difSocios: 0,
                activos: 0,
                difActivos: 0,
                adherentes: 0,
                difAdherentes: 0,
                comercios: 0,
                difComercios: 0,
                compras: 0,
                difCompras: 0,
                descuentos: 0,
                difDescuentos: 0,
                ingresos: 0,
                difIngresos: 0,
                socios: [],
                events: []
            })
            setIsLoading(false);
        }
        setIsLoading(false);

    }

    return (
        <>
            <CustomHeading title={'Inicio'}/>
            <HStack align={'top'}>
                <VStack width={'60%'}>
                    <Wrap spacing={5} height={'71vh'} overflowY={'scroll'}>
                        {isUser(rol) || isAdmin(rol) ?
                            <>
                                <Skeleton isLoaded={!isLoading} borderRadius={20}>
                                    <WrapItem p={7} pe={24} borderRadius={20} bg='#2988F3'>
                                        <Stat color={'whitesmoke'}>
                                            <StatLabel>
                                                <Text fontSize={25} fontWeight={'600'}>
                                                    Socios
                                                </Text>
                                            </StatLabel>
                                            <StatNumber fontSize={35} fontWeight={'800'}>{info.sociosNum}</StatNumber>
                                            <StatHelpText fontSize={20}>
                                                <StatArrow type={info.difSocios >= 0 ? 'increase' : 'decrease'}/>
                                                {Math.abs(info.difSocios)}
                                            </StatHelpText>
                                        </Stat>

                                    </WrapItem>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading} borderRadius={20}>
                                    <WrapItem p={7} pe={24} borderRadius={20} bg='#76829C'>
                                        <Stat color={'whitesmoke'}>
                                            <StatLabel>
                                                <Text fontSize={25} fontWeight={'600'}>
                                                    Activos
                                                </Text>
                                            </StatLabel>
                                            <StatNumber fontSize={35} fontWeight={'800'}>{info.activos}</StatNumber>
                                            <StatHelpText fontSize={20}>
                                                <StatArrow type={info.difActivos >= 0 ? 'increase' : 'decrease'}/>
                                                {Math.abs(info.difActivos)}
                                            </StatHelpText>
                                        </Stat>
                                    </WrapItem>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading} borderRadius={20}>
                                    <WrapItem p={7} pe={24} borderRadius={20} bg='#CC7C2C'>
                                        <Stat color={'whitesmoke'}>
                                            <StatLabel>
                                                <Text fontSize={25} fontWeight={'600'}>
                                                    Adherentes
                                                </Text>
                                            </StatLabel>
                                            <StatNumber fontSize={35} fontWeight={'800'}>{info.adherentes}</StatNumber>
                                            <StatHelpText fontSize={20}>
                                                <StatArrow type={info.difAdherentes >= 0 ? 'increase' : 'decrease'}/>
                                                {Math.abs(info.difAdherentes)}
                                            </StatHelpText>
                                        </Stat>
                                    </WrapItem>
                                </Skeleton>
                            </>
                            : []
                        }
                        {isAdmin(rol) ?
                            <>

                                <Skeleton isLoaded={!isLoading} borderRadius={20}>
                                    <WrapItem p={7} pe={24} borderRadius={20} bg='#D12D33'>
                                        <Stat color={'whitesmoke'}>
                                            <StatLabel>
                                                <Text fontSize={25} fontWeight={'600'}>
                                                    Comercios
                                                </Text>
                                            </StatLabel>
                                            <StatNumber fontSize={35} fontWeight={'800'}>{info.comercios}</StatNumber>
                                            <StatHelpText fontSize={20}>
                                                <StatArrow type={info.difComercios >= 0 ? 'increase' : 'decrease'}/>
                                                {Math.abs(info.difComercios)}
                                            </StatHelpText>
                                        </Stat>
                                    </WrapItem>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading} borderRadius={20}>
                                    <WrapItem p={7} pe={20} borderRadius={20} bg='#BC5B54'>
                                        <Stat color={'whitesmoke'}>
                                            <StatLabel>
                                                <Text fontSize={25} fontWeight={'600'}>
                                                    Compras de {format(new Date(), 'MMMM', {locale: es})}
                                                </Text>
                                            </StatLabel>
                                            <StatNumber fontSize={35}
                                                        fontWeight={'800'}>${Number(info.compras).toFixed(2)}</StatNumber>
                                            <StatHelpText fontSize={20}>
                                                <StatArrow type={info.difCompras >= 0 ? 'increase' : 'decrease'}/>
                                                ${Math.abs(info.difCompras).toFixed(2)}
                                            </StatHelpText>
                                        </Stat>
                                    </WrapItem>
                                </Skeleton>
                            </>
                            : []
                        }
                    </Wrap>
                </VStack>
                <Center height='65vh'>
                    <Divider orientation='vertical'/>
                </Center>
                <VStack align={'left'} width={'40%'}>
                    <Skeleton isLoaded={!isLoading} borderRadius={20}>
                        <Heading size={'lg'}>Cumpleaños</Heading>
                        <FullCalendar
                            height={500}
                            headerToolbar={{end: ''}}
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                            locale={'es-AR'}
                            titleFormat={{month: 'long', year: 'numeric'}}
                            events={info.events}
                            eventClick={showEventInfo}
                        />
                    </Skeleton>
                </VStack>
            </HStack>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={() => setIsOpen(false)}
                size='sm'
            >
                <DrawerOverlay/>
                <DrawerContent zIndex={1000}>
                    <DrawerBody>
                        <VStack align='stretch'>
                            {partners ?
                                partners.map((p: any) => (
                                    <Box
                                        key={p.fileNum}
                                        bgColor={'linkedin.100'}
                                        borderRadius={10}
                                        padding={3}
                                    >

                                        <List>
                                            <ListItem>
                                                <Tag size={'lg'} key={p.name} variant='subtle'
                                                     colorScheme='linkedin'>
                                                    <TagLeftIcon boxSize='22px' as={FaRegSmile}/>
                                                    <TagLabel>
                                                        {p.name}
                                                    </TagLabel>
                                                </Tag>
                                            </ListItem>
                                            <ListItem mt={2}>
                                                <Tag size={'lg'} key={p.fileNum} variant='solid'
                                                     colorScheme='orange'>
                                                    <TagLeftIcon boxSize='18px' as={FaIdCard}/>
                                                    <TagLabel>
                                                        {p.fileNum}
                                                    </TagLabel>
                                                </Tag>
                                            </ListItem>
                                            <ListItem mt={2}>
                                                <Tag size={'lg'} key={p.age} variant='solid'
                                                     colorScheme='linkedin'>
                                                    <TagLeftIcon boxSize='18px' as={FaBirthdayCake}/>
                                                    <TagLabel>
                                                        {p.age}
                                                    </TagLabel>
                                                </Tag>
                                            </ListItem>
                                            <ListItem mt={2}>
                                                <Tag size={'lg'} key={p.phone} variant='solid'
                                                     colorScheme='green'>
                                                    <TagLeftIcon boxSize='18px' as={FaPhone}/>
                                                    <TagLabel>
                                                        {p.phone}
                                                    </TagLabel>
                                                </Tag>
                                            </ListItem>
                                        </List>
                                    </Box>
                                )) : []
                            }
                        </VStack>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => setIsOpen(false)}>
                            Cerrar
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}
