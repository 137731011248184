import {isAfter, isBefore, parse} from "date-fns";

export const welcome = () => {

    const now = parse(new Date().getHours() + ":" + new Date().getMinutes(),'HH:mm', new Date())

    const noon = parse('12:00','HH:mm', new Date())
    const afternoonEnd = parse('18:00','HH:mm', new Date())
    
    if (isBefore(now, noon)) {
        return BUENOS_DIAS
    } else if (isAfter(now, noon) && isBefore(now, afternoonEnd)) {
        return BUENAS_TARDES
    } else {
        return BUENAS_NOCHES
    }

}

const BUENOS_DIAS = 'Buenos días...'
const BUENAS_TARDES = 'Buenas tardes...'
const BUENAS_NOCHES = 'Buenas noches...'