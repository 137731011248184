export const validations = {
    nombreUsuario: {
        required: {
            value: true,
            message: "Debe ingresar un nombre de usuario",
        },
        pattern: {
            value: /^([ña-z0-9]*[._-]?[ña-z0-9]*[._-]?.{4,})$/,
            message: ['Debe contener al menos 4 caracteres',
                'Debe estar en minúscula',
                'Puede contener números',
                'Puede contener 1 punto',
                'Puede contener 1 guión',
                'Puede contener 1 guión bajo'],
        },
    },
    contrasena: {
        required: {
            value: true,
            message: "Debe ingresar una contraseña",
        },
        pattern: {
            value: /((?=.*\d)(?=.*[ña-z])(?=.*[ÑA-Z])(?=.*[\W]).{8,64})/,
            message: ['Debe contener al menos 8 caracteres',
                'Debe contener 1 mayúscula',
                'Debe contener 1 minúscula',
                'Debe contener 1 número',
                'Debe contener 1 caracter especial'],
        },
        custom: {
            isValid: (value: any) => contrasena = value,
        },
    },
    contrasenaCheck: {
        required: {
            value: true,
            message: "Debe ingresar una contraseña",
        },
        pattern: {
            value: /((?=.*\d)(?=.*[ña-z])(?=.*[ÑA-Z])(?=.*[\W]).{8,64})/,
            message: ['Debe contener al menos 8 caracteres',
                'Debe contener 1 mayúscula',
                'Debe contener 1 minúscula',
                'Debe contener 1 número',
                'Debe contener 1 caracter especial'],
        },
        custom: {
            isValid: (value: any) => value === contrasena,
            message: "Las contraseñas no coinciden",
        },
    },
    rol: {
        required: {
            value: true,
            message: "Debe seleccionar un rol",
        },
    },
    fechaAlta: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
}

export const validationsWithoutPassword = {
    nombreUsuario: {
        required: {
            value: true,
            message: "Debe ingresar un nombre de usuario",
        },
        pattern: {
            value: /^([ña-z0-9]*[._-]?[ña-z0-9]*[._-]?.{4,})$/,
            message: ['Debe contener al menos 4 caracteres',
                'Debe estar en minúscula',
                'Puede contener números',
                'Puede contener 1 punto',
                'Puede contener 1 guión',
                'Puede contener 1 guión bajo'],
        },
    },
    rol: {
        required: {
            value: true,
            message: "Debe seleccionar un rol",
        },
    },
    fechaAlta: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
}

let contrasena: string;