import {useFetch} from "../../../hooks/useFetch";
import {CustomComprasHeading} from "../components/CustomComprasHeading";
import {useEffect, useState} from "react";
import {
    Badge,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {Comercio} from "../../../interfaces/Comercio";
import {Link, useParams} from "react-router-dom";
import {FaPenAlt, FaTrash} from "react-icons/fa";
import {format, parseISO} from "date-fns";
import es from "date-fns/locale/es"
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {Compra} from "../../../interfaces/Compra";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {findComprasByParam} from "../../../helpers/findComprasByParam";
import {api} from "../../../api/api";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {TableSkeleton} from "../../../components/TableSkeleton";

export const ComprasSocio = () => {
    const {id} = useParams();
    const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [month, setMonth] = useState(new Date())
    const {data, isLoading, setIsLoading} = useFetch(`/compras/socio/${id}/${date}`)
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const toast = useCustomToast({})
    const {object}: any =
        useGetObjectByID('socios/get', id, 'el', 'socio')

    const getTotalCompras = () => {
        let total = 0

        if (!list) {
            return 0
        }

        list.map((compra: Compra) => {
            let monto = Number(compra.valorTotal)

            return total += monto
        })

        return total.toFixed(2)
    }

    const handleOnChangeMonth = async ({target}: any) => {

        let {value} = target

        //cannot be 01 of month becouse it changes the month input value to the last month
        value = value + "-02"
        setDate(value)
        try {
            setIsLoading(true)
            const res = await api.get(`/compras/socio/${id}/${value}`)
            if (res.status !== 200) {
                toast({title: `No se pudo obtener la lista de compras`, status: 'error'})
                setList([])
                return
            }
            setIsLoading(false)
            setList(res.data)
            setInitialList(res.data)

        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo obtener la lista de compras`, status: 'error'})
            }
        }
    }

    const handleDeleteCompra = async (id: string) => {
        try {
            await deleteObjectByID("compras", id)

            setList(list.filter((value: Comercio) => value.id !== id))
            setInitialList(initialList.filter((value: Comercio) => value.id !== id))
            toast({title: "Compra eliminada con éxito!", status: "success"})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            toast({title: "Compra no pudo ser eliminada!", status: "error"})
            setList([])
            return
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findComprasByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    useEffect(() => {
        // need for correctly select the month
        let newDate = date.slice(0, 7) + "-1"
        setMonth(new Date(newDate))
    }, [data, date]);

    return (
        <>

            <CustomComprasHeading title={`Compras de ${object ? object.nombre : ''}`}
                                  data={list}
                                  today={format(new Date(date), "yyyy-MM")}
                                  findParam={handleFindParam}
                                  onChangeMonth={handleOnChangeMonth}/>

            <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Descripción</Th>
                            <Th>Cuotas</Th>
                            <Th>Fecha de compra</Th>
                            <Th>Socio</Th>
                            <Th>Comercio</Th>
                            <Th>Monto</Th>
                            <Th>Última cuota</Th>
                            <Th>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list ?
                            list.map((compra: Compra) => (
                                <Tr key={compra.id}>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'14rem'}>{compra.descripcion}</Td>
                                    <Td isNumeric>{compra.numeroCuotas}
                                    </Td>
                                    <Td>
                                        {format(parseISO(compra.fecha), 'dd-MM-yyyy HH:mm')}
                                    </Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'14rem'}>
                                        {compra.socio.nombre + ' ' + compra.socio.apellido}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'14rem'}>
                                        {compra.comercio.nombre}
                                    </Td>
                                    <Td>
                                        ${Number(compra.valorTotal).toFixed(2)}
                                    </Td>
                                    <Td>
                                        {format(parseISO(compra.ultimaCuota), 'MMMM-yyyy',{locale: es}).toUpperCase()}
                                    </Td>

                                    <Td>
                                        <Link to={`/compras/editar/${compra.id}`}>
                                            <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                        aria-label={`editar ${compra.id}`} icon={<FaPenAlt/>}/>
                                        </Link>
                                        <IconButton size={'sm'} onClick={() => handleDeleteCompra(compra.id)}
                                                    mx={1}
                                                    colorScheme={'red'} aria-label={`borrar ${compra.id}`}
                                                    icon={<FaTrash/>}/>
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <TableSkeleton isLoading={isLoading}/>
            <Wrap justify={'center'} my={4}>
                <WrapItem>
                    <Badge colorScheme={'purple'}>
                        Compras de {format(month, 'MMMM', {locale: es})}: {list ? list.length : 0}
                    </Badge>
                </WrapItem>
                <WrapItem>
                    <Badge colorScheme={'teal'}>
                        Monto total: ${getTotalCompras()}
                    </Badge>
                </WrapItem>
            </Wrap>
        </>
    );
}
