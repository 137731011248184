import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const PrivateRouter = ({children = [] as any}) => {
    const token = useSelector((state:any) => state.store.token)

    return token ?
        children
        :
        <Navigate to={'/auth/login'}/> ;
}