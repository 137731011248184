import {
    Button,
    Divider,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Skeleton,
    SkeletonText
} from "@chakra-ui/react";
import {FaFileExcel, FaSearch} from "react-icons/fa";
import {BackButton} from "../../../components/BackButton";
import {AddButton} from "../../../components/AddButton";
import {useDownloadFile} from "../../../hooks/useDownloadFile";


export const CustomComprasHeading = ({
                                         title = '',
                                         findParam = [] as any,
                                         today = '',
                                         data = [],
                                         onChangeMonth = [] as any
                                     }) => {

    const {downloadFile, isDownloading} = useDownloadFile({value: data})

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Skeleton isLoaded={!title.includes('undefined')} height={38} borderRadius={8}>
                    <Heading size='lg'>{title}</Heading>
                </Skeleton>


                <HStack>

                    <InputGroup>
                        <Input
                            type={'month'}
                            value={today}
                            onChange={onChangeMonth}
                        />
                    </InputGroup>
                    <InputGroup maxWidth={180}>
                        <InputLeftElement

                            pointerEvents='none'
                            children={<FaSearch color={'lightgray'}/>}
                        />
                        <Input placeholder={'Buscar'} onChange={findParam}/>
                    </InputGroup>
                    <Button px={12}
                            isLoading={isDownloading}
                            disabled={data ? data.length === 0 : true}
                            onClick={() => downloadFile(`/compras/export_xls/${today}`)}
                            colorScheme={'green'}
                            variant={'solid'} aria-label={'download xls'}
                            leftIcon={<FaFileExcel/>}>Exportar</Button>
                    <AddButton pathTo={'/compras/nuevo'}/>
                    <BackButton pathTo={'/socios'}/>
                </HStack>
            </HStack>
            <Divider
                my={5}
            />
        </>
    )
}