import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Button
} from "@chakra-ui/react";

export const DownloadConfirm = ({isOpen, onClose, lastMonth, month, onDownloadConfirm, cancelRef}: any) => {

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                size={'lg'}
            >
                <AlertDialogOverlay backdropFilter='blur(10px)'>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Descargar débitos
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea descargar las compras del mes de&nbsp;
                            <Badge variant={'subtle'}
                                   colorScheme={'green'}>
                                {lastMonth}
                            </Badge>
                            &nbsp;que seran descontados el mes de&nbsp;
                            <Badge variant={'subtle'}
                                   colorScheme={'green'}>
                                {month}
                            </Badge> ?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button colorScheme='blue' onClick={onDownloadConfirm} ml={3}>
                                Descargar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}