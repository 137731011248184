import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL

export const api = axios.create({
    baseURL: `${API_URL}`,
    timeout: 55000,
})

const requestHandler = (req: any) => {
    if (req.url.includes('login') ) return req

    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`

    return req
}

export const statusForbidden = 403
export const statusInternalServerError = 500

const responseHandler = (res: any) => {

    // reload the token in every response
    localStorage.setItem("token",res.headers.token)

    return res;
}

api.interceptors.request.use(
    (request) => requestHandler(request),
    (error) =>  {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => {
        if (error.response &&
            error.response.status === statusForbidden) {
           window.location.assign('/#/auth/login')
        }

        if (error.response &&
            error.response.status === statusInternalServerError) {
            window.location.assign('/#/auth/login')
        }

        return Promise.reject(error)
    }
)
