import {FaSave} from "react-icons/fa";
import {Button} from "@chakra-ui/react";

export const SaveButton = ({isLoading, onSubmit}: any) => {
    return (
        <Button isLoading={isLoading}
                onClick={onSubmit}
                m={4} my={6} mr={0}
                type={"button"}
                leftIcon={<FaSave/>}
                colorScheme={'blue'}>
            Guardar
        </Button>
    )
}