import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Box, Button, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text} from "@chakra-ui/react";
import {Logo} from "./Logo";
import {
    FaBuilding,
    FaCogs,
    FaHome,
    FaListAlt,
    FaShieldAlt,
    FaShoppingCart,
    FaSignOutAlt,
    FaStream,
    FaTimes,
    FaUserFriends
} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/storeSlice";
import {isAdmin, isComercio, isUser} from "../store/auth/auth";

export const CustomMenu = () => {
    const {pathname} = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {rol} = useSelector((state: any) => state.store)

    return (
        (pathname.includes('login') ||
            pathname.includes('forgot') ||
            pathname.includes('signup') ||
            pathname.includes('new_password')) ? <></> :
            <Menu autoSelect={false}>
                {({isOpen}: any) => (
                    <>
                        <MenuButton bg={'#1B2223'}
                                    p={9}
                                    borderRadius={15}
                                    borderTopEndRadius={0}
                                    borderTopStartRadius={0}
                                    borderBottomStartRadius={0}
                                    color={'white'}
                                    fontSize={26}
                                    _hover={{bg: 'gray.700'}}
                                    _expanded={{bg: '#1B2223'}}
                                    isActive={isOpen} as={Button}>
                            {isOpen ? <FaTimes/> : <FaStream/>}
                        </MenuButton>
                        <MenuList bg={'#1B2223'}
                                  p={4}
                                  borderRadius={15}
                                  borderTopStartRadius={0}
                                  borderBottomStartRadius={0}
                                  color={'grey'}
                                  fontSize={22}
                                  borderWidth='0px'
                        >
                            <Box m={3}>
                                <Logo/>
                            </Box>
                            {isComercio(rol) ?
                                <>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('compras')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"compras"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaShoppingCart/>}</Icon><Text>Compras</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                </>
                                :
                                []
                            }

                            {isUser(rol) || isAdmin(rol) ?

                                <>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('/')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"inicio"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaHome/>}</Icon><Text>Inicio</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('socios')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"socios"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaUserFriends/>}</Icon><Text>Socios</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                </>
                                : []
                            }

                            {isAdmin(rol) ?
                                <>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('comercios')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"comercios"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaBuilding/>}</Icon><Text>Comercios</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('compras')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"compras"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaShoppingCart/>}</Icon><Text>Compras</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('descuentos')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"descuentos"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{
                                                <FaShoppingCart/>}</Icon><Text>Descuentos</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('categorias')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"categorias"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaListAlt/>}</Icon><Text>Categorías</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('variables')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"variables"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaCogs/>}</Icon><Text>Variables</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                    <MenuItem bg={'#1B2223'} onClick={() => navigate('usuarios')}
                                              _focus={{bg: 'gray.700', borderRadius: '10px'}}>
                                        <NavLink
                                            to={"usuarios"}
                                            style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                            <HStack><Icon mt={1}>{<FaShieldAlt/>}</Icon><Text>Usuarios</Text></HStack>
                                        </NavLink>
                                    </MenuItem>
                                </>
                                :
                                []
                            }

                            <MenuItem bg={'#1B2223'} ps={0}
                                      _focus={{bg: 'gray.700', borderRadius: '10px'}}
                                      onClick={() => dispatch(logout())}>
                                <NavLink to={"logout"}>
                                    <Box color={'red'}
                                         px={3} py={2}>
                                        <FaSignOutAlt/>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <Box bg={'#1B2223'} ps={0}>
                                <Text px={3} fontSize={12}>v{process.env.REACT_APP_VERSION}</Text>
                            </Box>
                        </MenuList>
                    </>
                )}
            </Menu>
    );
}
