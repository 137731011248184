import {NavLink, useLocation} from "react-router-dom";
import {HStack, Icon, IconButton, Text} from "@chakra-ui/react";
import {Logo} from "./Logo";
import {
    FaBuilding,
    FaCogs,
    FaHome,
    FaListAlt,
    FaShieldAlt,
    FaShoppingCart,
    FaSignOutAlt,
    FaUserFriends
} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/storeSlice";
import {isAdmin, isComercio, isUser} from "../store/auth/auth";

export const Navbar = () => {
    const {pathname} = useLocation()
    const dispatch = useDispatch()
    const {rol} = useSelector((state: any) => state.store)

    return (

        (pathname.includes('login') ||
            pathname.includes('forgot') ||
            pathname.includes('signup') ||
            pathname.includes('new_password')) ? <></> :
            <>
                <HStack
                    bg={'#1B2223'}
                    p={4}
                    borderRadius={15}
                    borderTopEndRadius={0}
                    borderTopStartRadius={0}
                    color={'grey'}
                    fontSize={20}
                    justify={'space-between'}
                >
                    <HStack spacing={6}>

                        <Logo/>
                        {isComercio(rol) ?
                            <NavLink
                                to={"compras"}
                                style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                <HStack><Icon mt={1}>{<FaShoppingCart/>}</Icon><Text>Compras</Text></HStack>
                            </NavLink>
                            :
                            []
                        }

                        {isUser(rol) || isAdmin(rol) ?

                            <>
                                <NavLink
                                    to={"inicio"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaHome/>}</Icon><Text>Inicio</Text></HStack>
                                </NavLink>
                                <NavLink
                                    to={"socios"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaUserFriends/>}</Icon><Text>Socios</Text></HStack>
                                </NavLink>
                            </>
                            : []
                        }

                        {isAdmin(rol) ?
                            <>
                                <NavLink
                                    to={"comercios"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaBuilding/>}</Icon><Text>Comercios</Text></HStack>
                                </NavLink>
                                <NavLink
                                    to={"compras"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaShoppingCart/>}</Icon><Text>Compras</Text></HStack>
                                </NavLink>
                                <NavLink
                                    to={"descuentos"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaShoppingCart/>}</Icon><Text>Descuentos</Text></HStack>
                                </NavLink>
                                <NavLink
                                    to={"categorias"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaListAlt/>}</Icon><Text>Categorías</Text></HStack>
                                </NavLink>
                                <NavLink
                                    to={"variables"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaCogs/>}</Icon><Text>Variables</Text></HStack>
                                </NavLink>
                                <NavLink
                                    to={"usuarios"}
                                    style={({isActive}) => isActive ? {color: 'white'} : {}}>
                                    <HStack><Icon mt={1}>{<FaShieldAlt/>}</Icon><Text>Usuarios</Text></HStack>
                                </NavLink>
                            </>
                            :
                            []
                        }


                    </HStack>
                    <HStack>
                        <Text fontSize={12}>v{process.env.REACT_APP_VERSION}</Text>
                        <NavLink
                            to={"logout"}>
                            <IconButton
                                onClick={() => dispatch(logout())}
                                colorScheme={'red'}
                                size={'lg'}
                                variant={'link'}
                                icon={<FaSignOutAlt/>} aria-label={'logout'}/>
                        </NavLink>
                    </HStack>

                </HStack>
            </>
    );
}
