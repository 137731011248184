export const findComprasByParam = (data = [] as any, param = '') => {
    if (param.length === 0) {
        return data
    }

    param = param.toLowerCase()
    let newList = [] as any

    for (let i = 0; i < data.length; i++) {
        const obj = {
            descripcion: data[i].descripcion,
            nombreSocio: data[i].socio.nombre,
            apellidoSocio: data[i].socio.apellido,
            numeroDocumento: data[i].socio.numeroDocumento,
            numeroCuenta: data[i].socio.numeroCuenta,
            nombreComercio: data[i].comercio.nombre,
            cuitComercio: data[i].comercio.cuit,
            valor: data[i].valor,
            valorCuota: data[i].valorCuota,
        }

        if (param.includes(' ')) {
            const combinado = (obj.apellidoSocio + obj.nombreSocio).replaceAll(' ', '').toLowerCase()
            const combinadoAlt = (obj.nombreSocio + obj.apellidoSocio).replaceAll(' ', '').toLowerCase()
            const combinadoCorto = (obj.nombreSocio.split(' ')[0] + obj.apellidoSocio).toLowerCase()

            const cleanParam = param.replaceAll(' ', '')

            if (combinado.includes(cleanParam) ||
                combinadoAlt.includes(cleanParam) ||
                combinadoCorto.includes(cleanParam)) {
                newList = [
                    ...newList,
                    data[i]
                ]

                continue
            }
        }

        const objValues = Object.values(obj)
        if (JSON.stringify(objValues).toLowerCase().includes(param)) {
            newList = [
                ...newList,
                data[i]
            ]
        }
    }

    return newList
}