import {CustomHeading} from "../../../components/CustomHeading";
import {useFetch} from "../../../hooks/useFetch";
import {
    Badge,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FaInfoCircle, FaPenAlt, FaTrash} from "react-icons/fa";
import {format, parseISO} from "date-fns";
import {Usuario} from "../../../interfaces/Usuario";
import {useEffect, useRef, useState} from "react";
import {findByParam} from "../helpers/findByParam";
import {DeleteConfirm} from "../../../components/DeleteConfirm";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {isComercio, isSocio} from "../../../store/auth/auth";
import {useSelector} from "react-redux";
import {TableSkeleton} from "../../../components/TableSkeleton";

export const Usuarios = () => {
    const {username} = useSelector((state: any) => state.store)
    const {data, isLoading} = useFetch("/usuarios/all")
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const toast = useCustomToast({})
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef: any = useRef()
    const [infoToDelete, setInfoToDelete] = useState({id: '', description: ''})

    const handleDeleteUsuario = async (id: string) => {
        try {
            await deleteObjectByID("usuarios", id)
            setList(list.filter((value: Usuario) => value.id !== id))
            setInitialList(initialList.filter((value: Usuario) => value.id !== id))
            toast({title: "Usuario eliminado con éxito!", status: "success"})
            onClose()
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            toast({title: "El usuario no pudo ser eliminado!", status: "error"})
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(data, value))
    }

    useEffect(() => {

        // need this filter to hide SUPERADMIN user if not logged in
        let list =  (username !== 'mfuhr91@gmail.com' && username !== 'mfuhr') ? data.filter((value: Usuario) => value.nombreUsuario !== 'mfuhr') : data
        list = (username !== 'mfuhr91@gmail.com' && username !== 'mfuhr') ? data.filter((value: Usuario) => value.nombreUsuario !== 'mfuhr91@gmail.com') : data

        setList(list)
        setInitialList(list)

    }, [data]);


    return (
        <>
            <CustomHeading title={'Usuarios'}
                           addBtnTo={'/usuarios/nuevo'}
                           data={list}
                           findParam={handleFindParam}/>

            <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Nombre de usuario</Th>
                            <Th>Rol</Th>
                            <Th>Fecha Alta</Th>
                            <Th maxWidth={'3rem'}>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list ?
                            list.map((usuario: Usuario) => (
                                <Tr key={usuario.id}>
                                    <Td>{usuario.nombreUsuario}</Td>
                                    <Td>{usuario.rol}</Td>
                                    <Td>{format(parseISO(usuario.fechaAlta), 'dd-MM-yyyy')}</Td>
                                    <Td maxWidth={'3rem'}>
                                        <Link to={`/usuarios/editar/${usuario.id}`}>
                                            <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                        aria-label={`editar ${usuario.id}`} icon={<FaPenAlt/>}/>
                                        </Link>
                                        {
                                            (isSocio(usuario.rol) || isComercio(usuario.rol)) ?
                                                <Tooltip borderRadius={8} mr={2} label={
                                                    <HStack><FaInfoCircle fontSize={'35px'}/>
                                                        <span>
                                                            Debe eliminar el
                                                            {isSocio(usuario.rol) ? ' socio ' : ' comercio '}
                                                            para eliminar este usuario.
                                                        </span>
                                                    </HStack> }
                                                         aria-label='usuarioTooltip'>
                                                    <IconButton
                                                        size={'sm'}
                                                        type={'button'}
                                                        mx={1}
                                                        colorScheme={'orange'}
                                                        aria-label={`info ${usuario.id}`}
                                                        icon={<FaInfoCircle/>}/>
                                                </Tooltip>
                                                :
                                                <IconButton size={'sm'} onClick={() => {
                                                    onOpen()
                                                    setInfoToDelete({id: usuario.id, description: usuario.nombreUsuario})
                                                }}
                                                            mx={1}
                                                            colorScheme={'red'}
                                                            aria-label={`borrar ${usuario.id}`}
                                                            icon={<FaTrash/>}
                                                />
                                        }
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <TableSkeleton isLoading={isLoading}/>
            <HStack justify={'space-around'} my={4}>
                <Badge colorScheme={'purple'}>Usuarios totales: {list ? list.length : 0} </Badge>
            </HStack>

            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'el'}
                           entity={'usuario'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDeleteUsuario}
                           cancelRef={cancelRef}
            />
        </>
    );
}
