import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    Heading,
    HStack,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    ListItem,
    UnorderedList,
    VStack,
    Wrap
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import favicon from "../../assets/img/logo.png";
import {FaEye, FaEyeSlash, FaSignInAlt} from "react-icons/fa";
import {api} from "../../api/api";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useCustomToast} from "../../hooks/useCustomToast";
import {BackButton} from "../../components/BackButton";
import {validations} from "./helpers/validations";
import {useForm} from "../../hooks/useForm";
import {errorCodeExpiredCode} from "../../utils/constants";

export const GeneratePassword = () => {
    const {pathname} = useLocation()
    const [URLSearchParam] = useSearchParams();
    const toast = useCustomToast({})
    const [submitted, setSubmitted] = useState(false)
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const [token, setToken] = useState('')

    const {form, onInputChange, errors} = useForm({validations})

    useEffect(() => {

        setToken(URLSearchParam.get("token")!)

        setIsMobile(false)
        if (window.innerWidth <= 1000) {
            setIsMobile(true)
        }
    }, [window.innerWidth]);


    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        console.log(form.contrasena)
        console.log(errors)

        if (errors.length !== 0) {
            return
        }

        setIsLoading(true)

        try {
            await api.post('/new_password', {password: form.contrasena, token: token})
            toast({title: `Contraseña guardada correctamente!`, status: 'success'})
            navigate("/auth/login", {replace: true})
        } catch (error: any) {

            setTimeout(() => {
                setIsLoading(false)
            }, 2000)

            setIsLoading(false)

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeExpiredCode) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `El tiempo de recuperación ha expirado, comience el proceso nuevamente.`,
                        status: 'error'
                    })
                }
                navigate("/forgot", {replace: true})
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({
                    title: `El sistema no esta disponible temporalmente, intente nuevamente en unos minutos.`,
                    status: 'error'
                })
            }
        }
    }

    return (
        <>
            <HStack justify={'center'}>

                <Center w={isMobile ? '100%' : '50%'}
                        h={650}
                        borderRadius={20}>
                    <VStack>

                        <Image mb={5} src={favicon} alt='favicon' w={56}/>
                        <Wrap w={isMobile ? '100%' : '70%'}
                              bg={'#027297'}
                              p={10}
                              borderRadius={20}
                              color={'#F5F5F5'}>
                            <Heading size={'lg'}>Generar nueva contraseña</Heading>
                            <FormControl pt={5} isInvalid={submitted && errors.contrasena} isRequired>
                                <InputGroup>
                                    <Input
                                        autoComplete={'new-password'}
                                        py={6}
                                        fontSize={isMobile ? 'md' : 'lg'}
                                        bg={'#F5F5F5'}
                                        color={'black'}
                                        name={'contrasena'}
                                        placeholder={"Ingrese una contraseña"}
                                        type={showPassword ? 'text' : 'password'}
                                        value={form.contrasena || []}
                                        onChange={onInputChange}
                                    />
                                    <InputRightElement width={'4rem'} py={'1.6rem'}>
                                        <IconButton size={'md'}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    fontSize={isMobile ? 20 : 24}
                                                    color={'#027297'}
                                                    aria-label={'showPassword'}
                                                    icon={!showPassword ? <FaEyeSlash/> : <FaEye/>}/>
                                    </InputRightElement>
                                </InputGroup>
                                {submitted && errors.contrasena ?
                                    <>
                                        <Box bg={'#F5F5F5'}
                                             borderBottomRadius={'md'}
                                             padding={'2'}
                                             mt={'-2'}
                                        >
                                            {typeof errors.contrasena !== 'string' ?
                                                errors.contrasena.map((err: any, k: any) => (
                                                    <FormErrorMessage>
                                                        <UnorderedList>
                                                            <ListItem key={k}>{err}</ListItem>
                                                        </UnorderedList>
                                                    </FormErrorMessage>
                                                )) : <FormErrorMessage>{errors.contrasena}</FormErrorMessage>}
                                        </Box>
                                    </>
                                    : ([])}
                            </FormControl>
                            <FormControl py={5} isInvalid={submitted && errors.contrasenaCheck} isRequired>
                                <InputGroup>
                                    <Input
                                        autoComplete={'new-password'}
                                        py={6}
                                        fontSize={isMobile ? 'md' : 'lg'}
                                        bg={'#F5F5F5'}
                                        color={'black'}
                                        name={'contrasenaCheck'}
                                        placeholder={"Ingrese la contraseña nuevamente"}
                                        type={showPassword ? 'text' : 'password'}
                                        value={form.contrasenaCheck || []}
                                        onChange={onInputChange}
                                    />
                                    <InputRightElement width={'4rem'} py={'1.6rem'}>
                                        <IconButton size={'md'}
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    fontSize={isMobile ? 20 : 24}
                                                    color={'#027297'}
                                                    aria-label={'showPassword'}
                                                    icon={!showPassword ? <FaEyeSlash/> : <FaEye/>}/>
                                    </InputRightElement>
                                </InputGroup>
                                {submitted && errors.contrasenaCheck ?
                                    <>
                                        <Box bg={'#F5F5F5'}
                                             borderBottomRadius={'md'}
                                             padding={'2'}
                                             mt={'-2'}
                                        >

                                            {typeof errors.contrasenaCheck !== 'string' ?
                                                errors.contrasenaCheck.map((err: any, k: any) => (
                                                    <FormErrorMessage>
                                                        <UnorderedList>
                                                            <ListItem key={k}>{err}</ListItem>
                                                        </UnorderedList>
                                                    </FormErrorMessage>
                                                )) : <FormErrorMessage>{errors.contrasenaCheck}</FormErrorMessage>}
                                        </Box>
                                    </>
                                    : ([])}
                            </FormControl>
                            <Button isLoading={isLoading}
                                    size={'lg'} fontSize={isMobile ? 'lg' : 'xl'}
                                    onClick={onSubmit}
                                    type={"button"}
                                    leftIcon={<FaSignInAlt/>}
                                    colorScheme={'whiteAlpha'}>
                                Guardar
                            </Button>
                        </Wrap>
                        {
                            (pathname.includes('login') ||
                                pathname.includes('forgot') ||
                                pathname.includes('signup') ||
                                pathname.includes('new_password')) ?
                                <BackButton pathTo={"/auth/login"}/> :
                                <></>
                        }
                    </VStack>
                </Center>

            </HStack>
        </>
    )
}