import React from 'react';
import {Navbar} from "./components/Navbar";
import {Container, Show} from "@chakra-ui/react";
import {Router} from "./router/Router";
import {CustomMenu} from "./components/CustomMenu";

export const App = () => {
    return (
        <>
            <Container
                px={0}
                maxW='100%'
            >
                <Show above='xl'>
                    <Navbar/>
                </Show>
                <Show below={'xl'}>
                    <CustomMenu/>
                </Show>
                <Container mt={8}
                           maxW='100%'>
                    <Router/>
                </Container>
            </Container>
        </>
    )
}
