import {Image} from "@chakra-ui/react";
import favicon from "../assets/img/favicon.webp";

export const Logo = () => {
    return (
        <>
            <Image src={favicon} alt='favicon' maxW={'38px'}/>
        </>
    );
}
