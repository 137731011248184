import {
    Button,
    Center,
    FormControl,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    PinInput,
    PinInputField,
    Spinner,
    useDisclosure, Wrap, WrapItem
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {api} from "../../../api/api";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {errorCodeWrongCode} from "../../../utils/constants";
import {FaCheck, FaTimes} from "react-icons/fa";

export const CodeModal = ({
                              showModal = false,
                              onCloseModal = [] as any,
                              handleConfirm = [] as any,
                              isSaving = false,
                              id = '',
                              codeTemp = ''
                          }) => {
    const [isCodeComplete, setIsCodeComplete] = useState(false)
    const [isValidating, setIsValidating] = useState(false)
    const [isCodeOK, setIsCodeOK] = useState(false)
    const [code, setCode] = useState('')
    const toast = useCustomToast({})

    const Overlay = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(5px)'
        />
    )

    useEffect(() => {
        if (showModal) onOpen()
    }, [showModal]);

    useEffect(() => {
        if (codeTemp !== '') {
            validateCode(codeTemp.toString())
        }
    }, [codeTemp]);


    const handleCodeOnChange = (value: string) => {
        setCode(value)
    }

    const validateCode = async (value: string) => {
        setIsValidating(true)

        let body = {
            "id": id,
            "code": Number(value)
        }

        setIsCodeComplete(true)
        try {
            await api.post(`/socios/validate_code`, body)

        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            try {
                await api.post(`/save_log`, {
                    "Code": error.response.status,
                    "Msg": `cannot save compra in frontend - ${JSON.stringify(error.response.data)}`
                })
            } catch (error: any) {
                console.error("error trying to send log to backend", error)
            }

            setTimeout(() => {
                setIsValidating(false)
            }, 1000)

            setIsCodeOK(false)
            setCode('')
            /* if (error.response.data.Code === errorCodeExpiredCode) {
                 if (!toast.isActive("custom-toast")) {
                     toast({
                         title: `El código de confirmación de compra ha expirado!`,
                         status: 'error'
                     })
                 }
             }*/

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeWrongCode) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `El código de confirmación de compra es incorrecto!`,
                        status: 'error'
                    })
                }
            }

            if (!toast.isActive("custom-toast")) {
                toast({
                    title: `No se pudo validar el código de confirmación de compra!`,
                    status: 'error'
                })
            }

            setTimeout(() => {
                setIsCodeComplete(false)
            }, 2000)
            return
        }
        try {
            await api.post(`/save_log`, {"Code": 200, "Msg": "code successfully validated in frontend"})
        } catch (error: any) {
            console.error("error trying to send log to backend", error)
        }
        setTimeout(() => {
            setIsValidating(false)
            setIsCodeOK(true)
        }, 1000)
    }

    const handleOnClose = () => {
        setCode('')
        setIsCodeComplete(false)
        setIsCodeOK(false)
        onCloseModal()
        onClose()
    }

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [overlay,] = useState(<Overlay/>)

    return (
        <>
            <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={handleOnClose}>
                {overlay}
                <ModalContent>
                    <ModalHeader>
                        {isCodeOK ?
                            'El código de confirmación es correcto'
                            :
                            'Ingresar el código de confirmación'}
                    </ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <Center>
                                {
                                    isValidating ?
                                        <Spinner
                                            thickness='4px'
                                            speed='0.90s'
                                            emptyColor='gray.200'
                                            color='blue.500'
                                            size='xl'/>
                                        :
                                        isCodeComplete && isCodeOK ?
                                            <Wrap>
                                                <WrapItem>
                                                    <Button leftIcon={<FaTimes/>}
                                                            colorScheme={'red'}
                                                            onClick={handleOnClose}>Cancelar</Button>
                                                </WrapItem>
                                                <WrapItem>
                                                    <Button leftIcon={<FaCheck/>}
                                                            isLoading={isSaving}
                                                            colorScheme={'green'}
                                                            onClick={handleConfirm}>
                                                        Confirmar compra
                                                    </Button>
                                                </WrapItem>
                                            </Wrap>
                                            :
                                            <PinInput
                                                size={'lg'}
                                                type={'number'}
                                                isInvalid={false}
                                                defaultValue={code}
                                                value={code}
                                                onComplete={validateCode}
                                                onChange={handleCodeOnChange}
                                            >
                                                <PinInputField m={2} autoFocus/>
                                                <PinInputField m={2}/>
                                                <PinInputField m={2}/>
                                                <PinInputField m={2}/>
                                            </PinInput>
                                }
                            </Center>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        {
                            isValidating ?
                                []
                                :
                                !isCodeOK ?
                                    <Button colorScheme={'red'} onClick={handleOnClose}>Cancelar</Button>
                                    : []
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}