import {
    Badge,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";

import {useFetch} from "../../../hooks/useFetch";
import {FaList, FaPenAlt, FaTrash} from "react-icons/fa";
import {Link} from "react-router-dom";
import {Socio} from "../../../interfaces/Socio";
import {CustomHeading} from "../../../components/CustomHeading";
import {findByParam} from "../../../helpers/findByParam";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {isUser} from "../../../store/auth/auth";
import {DeleteConfirm} from "../../../components/DeleteConfirm";
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {api} from "../../../api/api";
import {TableSkeleton} from "../../../components/TableSkeleton";
import {format, parseISO} from "date-fns";

export const Socios = () => {
    const {rol} = useSelector((state: any) => state.store)
    const {data, isLoading, setIsLoading} = useFetch("/socios/all")
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const exportXLSPath = "/socios/export_xls"
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef: any = useRef()
    const [infoToDelete, setInfoToDelete] = useState({id: '', description: ''})
    const toast = useCustomToast({})
    const [showDeleted, setShowDeleted] = useState(false)

    const handleShowBajas = async ({target}: any) => {
        setList([])
        setInitialList([])
        setShowDeleted(false)

        const {checked} = target

        let path = "/socios/all"
        let errMsg = "No se pudo obtener la lista de socios!"
        if (checked) {
            path = "/socios/bajas"
            errMsg = "No se pudo obtener la lista de socios dados de baja!"
            setShowDeleted(true)
        }

        try {
            setIsLoading(true)
            const res = await api.get(path)
            setList(res.data)
            setInitialList(res.data)

        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            toast({title: errMsg, status: 'error'})
            setIsLoading(false)
        }
        setIsLoading(false)
    }


    const handleDeleteSocio = async (id: string) => {
        try {
            await deleteObjectByID("socios", id)

            setList(list.filter((value: Socio) => value.id !== id))
            setInitialList(initialList.filter((value: Socio) => value.id !== id))
            toast({title: "Socio eliminado con éxito!", status: "success"})
            onClose()
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            toast({title: "El socio no pudo ser eliminado!", status: "error"})
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    return (
        <>
            <CustomHeading title={'Socios'}
                           addBtnTo={'/socios/nuevo'}
                           data={list}
                           findParam={handleFindParam}
                           path={exportXLSPath}
                           handleShowBajas={handleShowBajas}/>
            <TableContainer overflowY={'scroll'} maxH={'63vh'}>

                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Legajo</Th>
                            <Th>Documento</Th>
                            <Th>Apellido</Th>
                            <Th>Nombre</Th>
                            <Th>Teléfono</Th>
                            <Th>Direccion</Th>
                            <Th>Habil.</Th>
                            <Th>Correo</Th>
                            {showDeleted ?
                            <Th>Fecha de eliminación</Th>
                            : []
                            }
                            <Th>Opciones</Th>
                        </Tr>
                    </Thead>


                    <Tbody>
                        {list ?
                            list.map((socio: Socio) => (
                                <Tr key={socio.id}>
                                    <Td>{socio.legajo}</Td>
                                    <Td>{socio.numeroDocumento}</Td>
                                    <Td>{socio.apellido}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'10rem'}>{socio.nombre}</Td>
                                    <Td maxWidth={'7rem'} isNumeric>{socio.telefono}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'13rem'}>{socio.direccion}</Td>
                                    <Td>{socio.habilitado ? "SI" : "NO"}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'12rem'}>{socio.correo}</Td>
                                    {showDeleted ?
                                        <Td>
                                            {format(parseISO(socio.fechaBaja!), 'dd-MM-yyyy')}
                                        </Td>
                                        : []
                                    }
                                    <Td>
                                        {isUser(rol) ?
                                            <>
                                                <Link to={`/socios/editar/${socio.id}`}>
                                                    <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                                aria-label={`editar ${socio.id}`}
                                                                icon={<FaPenAlt/>}/>
                                                </Link>


                                            </>
                                            :
                                            <>
                                                <Link to={`/compras/socio/${socio.id}`}>
                                                    <IconButton size={'sm'} mx={1} colorScheme={'teal'}
                                                                aria-label={`compras ${socio.id}`}
                                                                icon={<FaList/>}/>
                                                </Link>
                                                <Link to={`/socios/editar/${socio.id}`}>
                                                    <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                                aria-label={`editar ${socio.id}`}
                                                                icon={<FaPenAlt/>}/>
                                                </Link>
                                                <IconButton size={'sm'} onClick={() => {
                                                    onOpen()
                                                    setInfoToDelete({
                                                        id: socio.id,
                                                        description: socio.apellido + " " + socio.nombre
                                                    })
                                                }}
                                                            mx={1}
                                                            colorScheme={'red'} aria-label={`borrar ${socio.id}`}
                                                            icon={<FaTrash/>}/>
                                            </>
                                        }
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <TableSkeleton isLoading={isLoading}/>

            <HStack justify={'space-around'} my={4}>
                <Badge colorScheme={'purple'}>Socios totales: {list ? list.length : 0} </Badge>
            </HStack>

            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'el'}
                           entity={'socio'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDeleteSocio}
                           cancelRef={cancelRef}
            />
        </>
    );
}
