
const ROL_ADMIN = 'ADMIN'
const ROL_USUARIO = 'USUARIO'
const ROL_COMERCIO = 'COMERCIO'
const ROL_SOCIO = 'SOCIO'



export const isAdmin = (rol: string) => {
    return rol === ROL_ADMIN
}

export const isUser = (rol: string) => {
    return rol === ROL_USUARIO
}

export const isComercio = (rol: string) => {
    return rol === ROL_COMERCIO
}

export const isSocio = (rol: string) => {
    return rol === ROL_SOCIO
}
