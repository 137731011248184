import {
    Badge,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import {CustomHeading} from "../../../components/CustomHeading";
import {Link} from "react-router-dom";
import {FaPenAlt, FaTrash} from "react-icons/fa";
import {useFetch} from "../../../hooks/useFetch";
import {Comercio} from "../../../interfaces/Comercio";
import {findByParam} from "../../../helpers/findByParam";
import {useEffect, useRef, useState} from "react";
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {DeleteConfirm} from "../../../components/DeleteConfirm";
import {TableSkeleton} from "../../../components/TableSkeleton";

export const Comercios = () => {
    const {data, isLoading} = useFetch("/comercios/all")
    const toast = useCustomToast({})
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const exportXLSPath = "/comercios/export_xls"
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef: any = useRef()
    const [infoToDelete, setInfoToDelete] = useState({id: '', description:''})

    const handleDeleteComercio = async (id: string) => {
        try {
            await deleteObjectByID("comercios", id)

            setList(list.filter((value: Comercio) => value.id !== id))
            setInitialList(initialList.filter((value: Comercio) => value.id !== id))
            toast({title: "Comercio eliminado con éxito!", status: "success"})
            onClose()
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status:'info'
                    })
                }
                return
            }

            toast({title: "El comercio no pudo ser eliminado!", status: "error"})
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    return (
        <>
            <CustomHeading title={'Comercios'}
                           addBtnTo={'/comercios/nuevo'}
                           data={list}
                           findParam={handleFindParam}
                           path={exportXLSPath}/>

            <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Cuit</Th>
                            <Th>Nombre</Th>
                            <Th>Contacto</Th>
                            <Th>Teléfono</Th>
                            <Th>Direccion</Th>
                            <Th>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list ?
                            list.map((comercio: Comercio) => (
                                <Tr key={comercio.id}>
                                    <Td isNumeric>{comercio.cuit}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'16rem'}>{
                                        comercio.nombre}
                                    </Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'14rem'}>
                                        {comercio.contacto}
                                    </Td>
                                    <Td isNumeric>{comercio.telefono}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'14rem'}>
                                        {comercio.direccion}
                                    </Td>
                                    {/*  <Td>{format(parseISO(comercio.fechaAlta), 'dd-MM-yyyy')}</Td>*/}
                                    <Td>
                                        <Link to={`/comercios/editar/${comercio.id}`}>
                                            <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                        aria-label={`editar ${comercio.id}`} icon={<FaPenAlt/>}/>
                                        </Link>
                                        <IconButton size={'sm'} onClick={() => {
                                            onOpen()
                                            setInfoToDelete({id: comercio.id, description: comercio.nombre})
                                        }}
                                                    mx={1}
                                                    colorScheme={'red'} aria-label={`borrar ${comercio.id}`}
                                                    icon={<FaTrash/>}/>
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <TableSkeleton isLoading={isLoading}/>
            <HStack justify={'space-around'} my={4}>
                <Badge colorScheme={'purple'}>Comercios totales: {list ? list.length : 0} </Badge>
            </HStack>

            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'el'}
                           entity={'comercio'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDeleteComercio}
                           cancelRef={cancelRef}
            />
        </>
    );
}
