import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftElement, Switch
} from "@chakra-ui/react";
import {FaFileExcel, FaSearch} from "react-icons/fa";
import {AddButton} from "./AddButton";
import {useDownloadFile} from "../hooks/useDownloadFile";

export const CustomHeading = ({
                                  title = '',
                                  findParam = [] as any,
                                  addBtnTo = '',
                                  data = [],
                                  path = '',
                                  handleShowBajas = [] as any,
                              }) => {

    const {downloadFile, isDownloading} = useDownloadFile({value: data})

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading size='lg'>{title}</Heading>

                <HStack>
                    {!title.includes('Socios') ? [] :
                        <FormControl display='flex' alignItems='center' maxWidth={160}>
                            <FormLabel htmlFor='bajas' mb='0'>
                                Mostrar bajas
                            </FormLabel>
                            <Switch id='bajas'
                                    onChange={handleShowBajas}
                            />
                        </FormControl>
                    }

                    {title.includes('Inicio') ? [] :
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaSearch color={'lightgray'}/>}
                            />
                            <Input placeholder={'Buscar'} onChange={findParam}/>
                        </InputGroup>
                    }
                    {title.includes('Inicio') || title.includes('Usuarios') || title.includes('Variables') ?
                        [] :
                        <Button px={9}
                                isLoading={isDownloading}
                                disabled={data ? data.length === 0 : true} onClick={() => downloadFile(path)}
                                colorScheme={'green'} variant={'solid'} aria-label={'download xls'}
                                leftIcon={<FaFileExcel/>}>Exportar</Button>
                    }

                    {(addBtnTo !== '') ? (
                        <>
                            <AddButton pathTo={addBtnTo}/>
                        </>

                    ) : []
                    }
                </HStack>
            </HStack>
            <Divider
                my={5}
            />
        </>
    )
}


