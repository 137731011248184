import {FaPlus} from "react-icons/fa";
import {Button} from "@chakra-ui/react";
import {Link} from "react-router-dom";

export const AddButton = ({pathTo}: any) => {
    return (
        <Link to={pathTo}>
            <Button leftIcon={<FaPlus/>} colorScheme={'blue'}>
                Agregar
            </Button>
        </Link>
    )
}