export const validations = {
    nombre: {
        required: {
            value: true,
            message: "Debe ingresar un nombre",
        },
        pattern: {
            value: /^[-ña-zÑA-Z0-9\s]*$/,
            message: "El nombre solo debe contener letras",
        },
    },
    cuit: {
        required: {
            value: true,
            message: "Debe ingresar un cuit",
        },
        custom: {
            isValid: (value: any) => value?.length >= 11,
            message: "Debe ingresar al menos 11 dígitos",
        },
    },
    contacto: {
        required: {
            value: true,
            message: "Debe ingresar un contacto",
        },
        pattern: {
            value: /^[ña-zÑA-Z\s]*$/,
            message: "El contacto solo debe contener letras",
        },
    },
    fechaAlta: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
    telefono: {
        required: {
            value: true,
            message: "Debe ingresar un teléfono",
        },
        custom: {
            isValid: (value: any) => value?.length >= 6,
            message: "Debe ingresar al menos 6 dígitos",
        },
    },
    direccion: {
        required: {
            value: true,
            message: "Debe ingresar una dirección",
        },
    },
    localidad: {
        required: {
            value: true,
            message: "Debe seleccionar una localidad",
        },
    },
    categoria: {
        required: {
            value: true,
            message: "Debe seleccionar una categoria",
        },
    },
    correo: {
        required: {
            value: true,
            message: "Debe ingresar un correo",
        },
        pattern: {
            value: /[ña-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[ña-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?\.)+[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?/,
            message: "El correo debe tener el formato 'alias@mail.com'",
        },
    },
}