import {createSlice} from "@reduxjs/toolkit";

export const storeSlice = createSlice({
    name: 'store',
    initialState: {
        prevMonth: null,
        lastComprasPath: '',
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
        rol: localStorage.getItem("rol"),
        comercioLogged: JSON.parse(localStorage.getItem("comercio")!),
    },
    reducers: {
        login: (state, action) => {
            state.username = action.payload.username
            state.token = action.payload.token
            state.rol = action.payload.rol
            state.comercioLogged = action.payload.comercio

            localStorage.setItem("token", action.payload.token)
            localStorage.setItem("username", action.payload.username)
            localStorage.setItem("rol", action.payload.rol)
            localStorage.setItem("comercio", JSON.stringify(action.payload.comercio))
        },
        logout: (state) => {
            state.username = null
            state.token = null
            state.rol = null
            state.comercioLogged = null

            localStorage.clear()
        },
        setPrevMonth: (state, action) => {
            state.prevMonth = action.payload
        },
        remPrevMonth: (state) => {
            state.prevMonth = null
        },
        setLastComprasPath: (state, action) => {
            state.lastComprasPath = action.payload
        },
        remLastComprasPath: (state) => {
            state.lastComprasPath = ''
        }
    }
})


export const {login, logout, setPrevMonth, remPrevMonth, setLastComprasPath, remLastComprasPath} = storeSlice.actions