import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard} from "../pages/Dashboard";
import {Comercios} from "../pages/comercios/pages/Comercios";
import {Compras} from "../pages/compras/pages/Compras";
import {FormSocio} from "../pages/socios/pages/FormSocio";
import {Socios} from "../pages/socios/pages/Socios";
import {SociosRouter} from "./SociosRouter";
import {ComerciosRouter} from "./ComerciosRouter";
import {FormComercio} from "../pages/comercios/pages/FormComercio";
import {CategoriasRouter} from "./CategoriasRouter";
import {ComprasRouter} from "./ComprasRouter";
import {Categorias} from "../pages/categorias/pages/Categorias";
import {FormCategoria} from "../pages/categorias/pages/FormCategoria";
import {Usuarios} from "../pages/usuarios/pages/Usuarios";
import {UsuariosRouter} from "./UsuariosRouter";
import {FormUsuario} from "../pages/usuarios/pages/FormUsuario";
import {FormCompra} from "../pages/compras/pages/FormCompra";
import {ComprasSocio} from "../pages/socios/pages/ComprasSocio";
import {Descuentos} from "../pages/descuentos/pages/Descuentos";
import {VariablesRouter} from "./VariablesRouter";
import {Variables} from "../pages/variables/pages/Variables";
import {FormVariable} from "../pages/variables/pages/FormVariable";
import {Login} from "../pages/Login";
import {PrivateRouter} from "./PrivateRouter";
import {useSelector} from "react-redux";
import {isAdmin, isComercio, isUser} from "../store/auth/auth";
import {ForgotPassword} from "../pages/password/ForgotPassword";
import {GeneratePassword} from "../pages/password/GeneratePassword";

export const Router = () => {
    const {rol} = useSelector((state: any) => state.store)
    return (
        <Routes>
            <Route path={'/auth/login'} element={<Login/>}/>
            <Route path={'/forgot'} element={<ForgotPassword/>}/>
            <Route path={'/signup'} element={<ForgotPassword/>}/>
            <Route path={'/new_password'} element={<GeneratePassword/>}/>
            <Route path={'/*'} element={
                <PrivateRouter>
                    <Routes>
                        {
                            (isAdmin(rol) || isUser(rol)) ?
                                <>
                                    <Route path={'inicio'} element={<Dashboard/>}/>
                                    <Route path={'socios/*'} element={
                                        <SociosRouter>
                                            <Routes>
                                                <Route path={''} element={<Socios/>}/>
                                                <Route path={'nuevo'} element={<FormSocio/>}/>
                                                <Route path={'editar/:id'} element={<FormSocio/>}/>
                                            </Routes>
                                        </SociosRouter>}>
                                    </Route>
                                    <Route path={'*'} element={<Navigate to={'inicio'}/>}></Route>
                                </>
                                :
                                []
                        }
                        {
                            (isAdmin(rol)) ?
                                <>
                                    <Route path={'comercios/*'} element={
                                        <ComerciosRouter>
                                            <Routes>
                                                <Route path={''} element={<Comercios/>}/>
                                                <Route path={'nuevo'} element={<FormComercio/>}/>
                                                <Route path={'editar/:id'} element={<FormComercio/>}/>
                                            </Routes>
                                        </ComerciosRouter>}>
                                    </Route>
                                    <Route path={'compras/*'} element={
                                        <ComprasRouter>
                                            <Routes>
                                                <Route path={''} element={<Compras/>}/>
                                                <Route path={'nuevo'} element={<FormCompra/>}/>
                                                <Route path={'editar/:id'} element={<FormCompra/>}/>
                                                <Route path={'socio/:id'} element={<ComprasSocio/>}/>
                                            </Routes>
                                        </ComprasRouter>}>
                                    </Route>
                                    <Route path={'descuentos'} element={<Descuentos/>}/>
                                    <Route path={'categorias/*'} element={
                                        <CategoriasRouter>
                                            <Routes>
                                                <Route path={''} element={<Categorias/>}/>
                                                <Route path={'nuevo'} element={<FormCategoria/>}/>
                                                <Route path={'editar/:id'} element={<FormCategoria/>}/>
                                            </Routes>
                                        </CategoriasRouter>}>
                                    </Route>
                                    <Route path={'usuarios/*'} element={
                                        <UsuariosRouter>
                                            <Routes>
                                                <Route path={''} element={<Usuarios/>}/>
                                                <Route path={'nuevo'} element={<FormUsuario/>}/>
                                                <Route path={'editar/:id'} element={<FormUsuario/>}/>
                                            </Routes>
                                        </UsuariosRouter>}>
                                    </Route>
                                    <Route path={'variables/*'} element={
                                        <VariablesRouter>
                                            <Routes>
                                                <Route path={''} element={<Variables/>}/>
                                                <Route path={'editar/:type'} element={<FormVariable/>}/>
                                            </Routes>
                                        </VariablesRouter>}>
                                    </Route>
                                </>
                                :
                                []
                        }
                        {
                            (isComercio(rol)) ?
                                <>
                                    <Route path={'compras/*'} element={
                                        <ComprasRouter>
                                            <Routes>
                                                <Route path={''} element={<Compras/>}/>
                                                <Route path={'nuevo'} element={<FormCompra/>}/>
                                                <Route path={'editar/:id'} element={<FormCompra/>}/>
                                            </Routes>
                                        </ComprasRouter>}>
                                    </Route>
                                    <Route path={'*'} element={<Navigate to={'compras'}/>}></Route>
                                </>
                                : []
                        }
                    </Routes>
                </PrivateRouter>
            }/>
        </Routes>
    );
}