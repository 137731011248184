export const validations = {
    contrasena: {
        required: {
            value: true,
            message: "Debe ingresar una contraseña",
        },
        pattern: {
            value: /((?=.*\d)(?=.*[ña-z])(?=.*[ÑA-Z])(?=.*[\W]).{8,64})/,
            message: ['Debe contener al menos 8 caracteres',
                'Debe contener 1 mayúscula',
                'Debe contener 1 minúscula',
                'Debe contener 1 número',
                'Debe contener 1 caracter especial'],
        },
        custom: {
            isValid: (value: any) => contrasena = value,
        },
    },
    contrasenaCheck: {
        required: {
            value: true,
            message: "Debe ingresar una contraseña",
        },
        pattern: {
            value: /((?=.*\d)(?=.*[ña-z])(?=.*[ÑA-Z])(?=.*[\W]).{8,64})/,
            message: ['Debe contener al menos 8 caracteres',
                'Debe contener 1 mayúscula',
                'Debe contener 1 minúscula',
                'Debe contener 1 número',
                'Debe contener 1 caracter especial'],
        },
        custom: {
            isValid: (value: any) => value === contrasena,
            message: "Las contraseñas no coinciden",
        },
    },
}

let contrasena: string;