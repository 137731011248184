import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay, Badge,
    Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr
} from "@chakra-ui/react";

export const ListAlertDialog = ({isOpen, onClose, pron, entity, list, description, cancelRef}: any) => {

    return (
        <>
            <AlertDialog
                id={description}
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                size={'lg'}
                preserveScrollBarGap
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Importante!
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {pron} {entity} &nbsp;
                            <Badge variant={'subtle'}
                                   colorScheme={'red'}>
                                {description}
                            </Badge>
                            &nbsp;
                            contiene información asociada! <br/>
                            Es necesario editar los siguientes elementos:
                            <TableContainer mt={4} overflowY={'scroll'} maxH={'50vh'}>
                                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                                    <Thead>
                                        <Tr>
                                            <Th>Nombre</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {list ?
                                            list.map((item: any) => (
                                                <Tr key={item.id}>
                                                    <Td><Badge variant='subtle'
                                                               colorScheme={'blue'}>{item.apellido!} {item.nombre}</Badge></Td>
                                                </Tr>
                                            )) : []
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Ok
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}