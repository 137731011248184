import {CustomHeading} from "../../../components/CustomHeading";
import {useFetch} from "../../../hooks/useFetch";
import {Badge, HStack, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FaPenAlt} from "react-icons/fa";
import {findByParam} from "../helpers/findByParam";
import {useEffect, useState} from "react";
import {Variable} from "../../../interfaces/Variable";
import {parseValue} from "../helpers/utils";
import {TableSkeleton} from "../../../components/TableSkeleton";

export const Variables = () => {
    const {data, isLoading} = useFetch("/variables/all")
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)
    }, [data]);

    return (
        <>
            <CustomHeading title={'Variables'}
                           data={list}
                           findParam={handleFindParam}/>
            <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Nombre</Th>
                            <Th>Valor</Th>
                            <Th maxWidth={'3rem'}>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list ?
                            list.map((variable: Variable) => (
                                <Tr key={variable.id}>
                                    <Td><Badge variant='subtle' colorScheme={'messenger'}>
                                        {variable.tipo === 'DiaCierreMes' ? 'día de cierre' : ''}
                                        {variable.tipo === 'ValorMaxItem' ? 'Valor máximo de item de descuento' : ''}
                                        {variable.tipo === 'ValorMaxDescuento' ? 'Valor máximo de descuento' : ''}
                                        {variable.tipo === 'CuotaSocial' ? 'Cuota social' : ''}
                                        {variable.tipo === 'Comision' ? 'Comisión' : ''}
                                        {variable.tipo === 'LimiteCredito' ? 'Límite de crédito' : ''}
                                    </Badge></Td>
                                    <Td><Badge variant='subtle' colorScheme={'blackAlpha'}>
                                        {
                                            variable.tipo === 'DiaCierreMes' ?
                                                'Día ' + variable.valor
                                                :
                                                parseValue(variable.tipo, variable.valor)
                                        }
                                    </Badge></Td>

                                    <Td maxWidth={'3rem'}>
                                        <Link to={`/variables/editar/${variable.tipo}`}>
                                            <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                        aria-label={`editar ${variable.tipo}`} icon={<FaPenAlt/>}/>
                                        </Link>
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <TableSkeleton isLoading={isLoading}/>
            <HStack justify={'space-around'} my={4}>
                <Badge colorScheme={'purple'}>Variables totales: {list ? list.length : 0} </Badge>
            </HStack>

        </>
    );
}
