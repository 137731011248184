import {Compra} from "../../../interfaces/Compra";

export const getNroCompra = (compra: Compra): string => {
    return "0".repeat(5 - compra.nro.toString().length)
        .concat(compra.nro.toString())
        .concat("/")
        .concat(compra.anio.toString())
}

export const isAutomaticCharge = (compra: Compra) => {
    return compra.descripcion === 'Cuota social' ||
        compra.descripcion === 'Cuota social adicional' ||
        compra.descripcion === 'Cuota deporte' ||
        compra.descripcion === 'Cuota salud'
}
