import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {App} from "./App";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import {HashRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/store";


const config = {
    initialColorMode: 'light',
    useSystemColorMode: true,
}

// #32338E logo color

const theme = extendTheme({config})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <ChakraProvider theme={theme}>
                    <App/>
                </ChakraProvider>
            </HashRouter>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
