export const validations = {
    nombre: {
        required: {
            value: true,
            message: "Debe ingresar un nombre",
        },
        pattern: {
            value: /^[ña-zÑA-Z\s]*$/,
            message: "El nombre solo debe contener letras",
        },
    },
    fechaAlta: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
}