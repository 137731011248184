import {
    Button,
    Center,
    FormControl,
    Heading,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    VStack,
    Wrap
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import favicon from "../../assets/img/logo.png";
import {FaEnvelope, FaTelegramPlane} from "react-icons/fa";
import {useDispatch} from "react-redux";
import {api} from "../../api/api";
import {useLocation, useNavigate} from "react-router-dom";
import {useCustomToast} from "../../hooks/useCustomToast";
import {BackButton} from "../../components/BackButton";
import {errorCodeNotFound} from "../../utils/constants";

export const ForgotPassword = () => {
    const {pathname} = useLocation()
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isSignUp, setIsSignUp] = useState(pathname.includes('signup'))

    const [email, setEmail] = useState('')

    useEffect(() => {
        setIsMobile(false)
        if (window.innerWidth <= 1000) {
            setIsMobile(true)
        }
    }, [window.innerWidth]);


    const onSubmit = async (event: any) => {
        event.preventDefault();

        setIsLoading(true)
        try {
            await api.post('/forgot', {email})
            toast({title: isSignUp ?
                    `Correo de generación de contraseña enviado a ${email}` :
                    `Correo de recuperación enviado a ${email}`,
                status: 'success'})

            navigate("/auth/login", {replace: false})
        } catch (error: any) {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)

            setIsLoading(false)

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeNotFound) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `El correo no esta registrado en el sistema.`,
                        status: 'error'
                    })
                }
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({
                    title: `El sistema no esta disponible temporalmente, intente nuevamente en unos minutos.`,
                    status: 'error'
                })

                return
            }
        }
    }

    return (
        <>
            <HStack justify={'center'}>

                <Center w={isMobile ? '100%' : '50%'}
                        h={650}
                        borderRadius={20}>
                    <VStack>

                        <Image mb={5} src={favicon} alt='favicon' w={56}/>
                        <Wrap w={isMobile ? '100%' : '70%'}
                              bg={'#027297'}
                              p={10}
                              borderRadius={20}
                              color={'#F5F5F5'}>
                            <Heading size={'lg'}>{isSignUp ? 'Enviar correo de generación de contraseña' : 'Enviar correo de recuperación'}</Heading>
                            <FormControl py={5}>
                                <InputGroup>
                                    <InputLeftElement width={'3rem'} py={'1.6rem'}>
                                        <Icon fontSize={isMobile ? 20 : 22} color={'#027297'}> <FaEnvelope/> </Icon>
                                    </InputLeftElement>
                                    <Input
                                        py={6}
                                        fontSize={isMobile ? 'md' : 'lg'}
                                        bg={'#F5F5F5'}
                                        color={'black'}
                                        name={'email'}
                                        placeholder={"Ingrese su correo"}
                                        type='text'
                                        value={email}
                                        onChange={({target}) => setEmail(target.value.toLowerCase())}
                                        onKeyDown={(event) => event.key === 'Enter' ? onSubmit(event) : []}
                                    />
                                </InputGroup>
                            </FormControl>
                            <Button isLoading={isLoading}
                                    size={'lg'} fontSize={isMobile ? 'lg' : 'xl'}
                                    onClick={onSubmit}
                                    type={"button"}
                                    leftIcon={<FaTelegramPlane/>}
                                    colorScheme={'whiteAlpha'}>
                                Enviar
                            </Button>
                        </Wrap>
                        {
                            (pathname.includes('login') ||
                                pathname.includes('forgot') ||
                                pathname.includes('signup') ||
                                pathname.includes('new_password')) ?
                                <BackButton pathTo={"/auth/login"}/> :
                                <></>
                        }
                    </VStack>
                </Center>

            </HStack>
        </>
    )
}