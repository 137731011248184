import {add, format, parseISO, subMonths} from "date-fns";
import {Badge, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure} from "@chakra-ui/react";
import {Compra} from "../../../interfaces/Compra";
import es  from 'date-fns/locale/es'
import {useEffect, useRef, useState} from "react";
import {useFetch} from "../../../hooks/useFetch";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {findComprasByParam} from "../../../helpers/findComprasByParam";
import {api} from "../../../api/api";
import {getNroCompra, isAutomaticCharge} from "../../compras/helpers/getNroCompra";
import {CustomDescuentosHeading} from "../components/CustomDescuentosHeading";
import {TableSkeleton} from "../../../components/TableSkeleton";
import {DeleteConfirm} from "../../../components/DeleteConfirm";
import {DownloadConfirm} from "../../../components/DownloadConfirm";
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {Socio} from "../../../interfaces/Socio";
import {useDownloadFile} from "../../../hooks/useDownloadFile";


export const Descuentos = () => {

    const [date, setDate] = useState(format(add(new Date(), {months: 1}), 'yyyy-MM-dd'))
    const [month, setMonth] = useState(new Date(date))
    const {data, isLoading, setIsLoading} = useFetch(`/descuentos/month_quote/${date}`)
    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const [toDownload, setToDownload] = useState({lastMonth: '', month: ''})
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {downloadFile, isDownloading} = useDownloadFile({value: null})
    const cancelRef: any = useRef()

    const toast = useCustomToast({})

    const getTotalDescuentosWithCommission = () => {
        let total = 0

        if (!list) {
            return 0
        }

        list.map((compra: Compra) => (
            total += Number(compra.valorCuota)
        ))

        return total.toFixed(2)
    }

    const getTotalDescuentos = () => {
        let total = 0

        if (!list) {
            return 0
        }

        list.map((compra: Compra) => (
            total += Number(compra.valor / compra.cuotas.length)
        ))

        return total.toFixed(2)
    }

    const getQuoteNumber = (compra: Compra) => {
        if (compra.numeroCuotas === 1) {
            return 1
        }

        for (const i in compra.cuotas) {
            if (compra.cuotas[i].fecha.includes(date.slice(0, 7))) {
                return compra.cuotas[i].numeroCuota
            }
        }
    }

    const handleOnChangeMonth = async ({target}: any) => {
        setList([])
        setInitialList([])

        let {value} = target

        //cannot be 01 of month becouse it changes the month input value to the last month
        value = value + "-02"
        setDate(value)
        try {
            setIsLoading(true)
            const res = await api.get(`/descuentos/month_quote/${value}`)
            if (res.status !== 200) {
                toast({title: `No se pudo obtener la lista de compras!`, status: 'error'})
                setList([])
                return
            }
            setIsLoading(false)
            setList(res.data)
            setInitialList(res.data)

        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            toast({title: `No se pudo obtener la lista de compras!`, status: 'error'})
        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findComprasByParam(initialList, value))
    }

    useEffect(() => {
    }, [month]);


    useEffect(() => {
        setList(data)
        setInitialList(data)

    }, [data]);

    useEffect(() => {
        // need for correctly select the month
        let newDate = date.slice(0, 7) + "-02"
        setMonth(new Date(newDate))
    }, [data, date]);

    const handleDownload = async (id: string) => {
        onClose()
        await downloadFile(`/descuentos/export_txt/${format(new Date(month), "yyyy-MM")}`)
    }

    const showDownloadConfirm = () => {
        const lastMonth = format(subMonths(Date.parse(date), 1), "MMMM", {locale: es})
        const actualMonth = format(month,"MMMM", {locale: es})
        onOpen()
        setToDownload({
            lastMonth: lastMonth.toString(),
            month: actualMonth
        })
    }

    return <>
        <CustomDescuentosHeading title={'Descuentos'}
                                 data={list}
                                 month={format(new Date(month), "yyyy-MM")}
                                 findParam={handleFindParam}
                                 onChangeMonth={handleOnChangeMonth}
                                 handleDownload={showDownloadConfirm}
                                 isDownloading={isDownloading}/>

        <TableContainer overflowY={'scroll'} maxH={'63vh'}>
            <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                <Thead>
                    <Tr>
                        <Th maxWidth={'6rem'}>Nro compra</Th>
                        <Th>Descripción</Th>
                        <Th>Cuota</Th>
                        <Th>Fecha de compra</Th>
                        <Th>Socio</Th>
                        <Th>Comercio</Th>
                        <Th>Monto</Th>
                        <Th>Monto + %</Th>
                        <Th>Última cuota</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {list ?
                        list.map((compra: Compra) => (
                            <Tr key={compra.id}>
                                <Td maxWidth={'7rem'}>
                                    <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                        <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                            {isAutomaticCharge(compra) ? '-/' + compra.anio : getNroCompra(compra)}
                                        </Badge>
                                    </Badge>
                                </Td>
                                <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                    maxWidth={'12rem'}>{compra.descripcion}</Td>
                                <Td isNumeric>{getQuoteNumber(compra) + "/" + compra.numeroCuotas}</Td>
                                <Td>
                                    {format(parseISO(compra.fecha), 'dd-MM-yyyy HH:mm')}
                                </Td>
                                <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                    maxWidth={'12rem'}>
                                    {compra.socio.apellido + ' ' + compra.socio.nombre}</Td>
                                <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                    maxWidth={'10rem'}>
                                    {compra.comercio.nombre}
                                </Td>
                                <Td>
                                    <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                        ${Number(compra.valor / compra.cuotas.length).toFixed(2)}
                                    </Badge>
                                </Td>
                                <Td>
                                    <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                        ${Number(compra.valorCuota).toFixed(2)}
                                    </Badge>
                                </Td>
                                <Td paddingY={4}>
                                    {format(parseISO(compra.ultimaCuota), 'MMMM-yyyy', {locale: es}).toUpperCase()}
                                </Td>
                            </Tr>
                        )) : []
                    }
                </Tbody>
            </Table>
        </TableContainer>
        <TableSkeleton isLoading={isLoading}/>
        <HStack justify={'center'} my={4}>
            <Badge colorScheme={'purple'}>
                Descuentos de {format(month, 'MMMM', {locale: es})}: {list ? list.length : 0}
            </Badge>
            <Badge colorScheme={'teal'}>
                Monto total: ${getTotalDescuentos()}
            </Badge>
            <Badge colorScheme={'teal'}>
                Monto total + %: ${getTotalDescuentosWithCommission()}
            </Badge>
        </HStack>

        <DownloadConfirm isOpen={isOpen}
                         onClose={onClose}
                         lastMonth={toDownload.lastMonth}
                         month={toDownload.month}
                         onDownloadConfirm={handleDownload}
                         cancelRef={cancelRef}
        />
    </>
}