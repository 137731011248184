import {
    Avatar,
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    IconButton,
    Input,
    NumberInput,
    NumberInputField,
    Select,
    Stack,
    Switch,
    Textarea,
    VStack,
    Wrap
} from "@chakra-ui/react";

import {useCallback, useEffect, useRef, useState} from "react";
import {FaCamera, FaCloudUploadAlt, FaPlus, FaTimes, FaTrash} from "react-icons/fa";
import {useForm} from "../../../hooks/useForm";
import {useNavigate, useParams} from "react-router-dom";
import {format, formatISO, parse, parseISO} from 'date-fns'
import {validations} from "../helpers/validations";
import Webcam from "react-webcam";
import {Familiar, Socio} from "../../../interfaces/Socio";
import uuid from "react-uuid";
import {api} from "../../../api/api";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {SaveButton} from "../../../components/SaveButton";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {errorCodeAlreadyCreated, errorCodeEmailAlreadyRegistered} from "../../../utils/constants";
import {getEstadosCiviles} from "../helpers/getEstadosCiviles";
import {useGetCuotaSocial} from "../hooks/useGetCuotaSocial";
import {BackButton} from "../../../components/BackButton";
import {Buffer} from "buffer";

export const FormSocio = () => {
    const {id} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [showWebcam, setShowWebcam] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const nameRef = useRef([] as any);
    const lNameRef = useRef([] as any);
    const docRef = useRef([] as any);
    const [selectedFile, setSelectedFile] = useState({inputName: '', file: ''} as any)
    const hoy = new Date().toISOString().slice(0, 10)
    const photoInputRef = useRef([] as any);
    const {object}: any =
        useGetObjectByID('socios/get', id, 'el', 'socio')

    const [localidades] = useState(['USHUAIA', 'TOLHUIN', 'RIO GRANDE'])
    const {cuotaSocialValor} = useGetCuotaSocial()


    const {form, onInputChange, setFormState, errors} = useForm({
        validations,
        fechaAlta: hoy
    })

    const handleOnAddFamiliar = () => {
        const id = uuid()

        if (!form.familiares || form.familiares.length === 0) {
            setFormState({
                ...form,
                familiares: [{id: id, nombre: '', apellido: '', parentezco: ''}]
            })
            return
        }

        let add = true
        for (const familiar of form.familiares) {
            if (familiar.nombre === '' || familiar.apellido === '' || familiar.parentezco === '') {
                add = false
            }
        }

        if (!add) {
            return
        }

        setFormState({
            ...form,
            familiares: [{id: id, nombre: '', apellido: '', parentezco: ''}, ...form.familiares]
        })
    }

    const handleDeleteFamiliar = (id: string) => {
        setFormState({
            ...form,
            familiares: form.familiares.filter((value: Familiar) => value.id !== id)
        })
    }

    useEffect(() => {
        form.abonaCuotaSocial = true
        if (object.id) {
            object.fechaAlta = format(parseISO(object.fechaAlta), 'yyyy-MM-dd')
            object.fechaBaja = object.fechaBaja ? format(parseISO(object.fechaBaja), 'yyyy-MM-dd') : null
            object.fechaNacimiento = format(parseISO(object.fechaNacimiento), 'yyyy-MM-dd')
            object.fechaIngresoLaboral = format(parseISO(object.fechaIngresoLaboral), 'yyyy-MM-dd')
            setFormState({...object, validations})
        }
    }, [object]);

    const handlePhotoUpload = () => {
        photoInputRef.current.click()
    }

    const handlePhotoDelete = () => {
        setFormState({
            ...form,
            foto: {
                id: '',
                url: ''
            }
        })
    }

    const onFileInputChange = async ({target}: any) => {
        const file = target.files[0]
        const name = target.name
        const reader = new FileReader()
        reader.onload = ({target}: any) => {
            setSelectedFile({inputName: name, file: file})
            setFormState({
                ...form,
                foto: {
                    id: form.foto ? form.foto.id : '',
                    url: target.result
                }
            })
        }
        reader.readAsDataURL(file)
    }

    // convert data url into File
    const dataURLtoFile = (dataUrl: string) => {

        const arr = dataUrl.split(',')
        const match = arr[0].match(/:(.*?);/)
        if (match == null) {
            return
        }

        const mime = match[1]
        const bstr = Buffer.from(arr[1], 'base64').toString()
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], "new_photo", {type: mime});
    }

    const getCuotaSocial = () => {
        if (!form.abonaCuotaSocial) {
            return null
        }

        return form.cuotaSocial ? form.cuotaSocial : cuotaSocialValor
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (form.familiares && form.familiares.length > 0) {
            for (const familiar of form.familiares) {
                if (familiar.nombre === '' || familiar.apellido === '' || familiar.parentezco === '') {
                    return
                }
            }
        }

        if (errors.length !== 0) {
            return
        }
        setIsSaving(true)

        let action = 'save'
        let status = 'guardado'
        if (id) {
            action = 'update'
            status = 'actualizado'
        }

        let foto
        if (selectedFile.file) {

            const formData = new FormData()

            formData.append('fotoID', form.foto.id)
            formData.append('file', selectedFile.file)
            formData.append('group', 'socios')

            try {
                foto = await api.post(`/fotos/upload`, formData)
            } catch (error: any) {
                if (error.response &&
                    error.response.status === 403) {
                    if (!toast.isActive("custom-toast")) {
                        toast({
                            id: "custom-toast",
                            title: `Sesión cerrada por inactividad`,
                            status: 'info'
                        })
                    }
                    return
                }

                setIsSaving(false)
                toast({title: `El socio no pudo ser ${status}!`, status: 'error'})
                return
            }

        }


        let socio: Socio = {...form}

        socio.fechaAlta = formatISO(parse(form.fechaAlta, 'yyyy-MM-dd', new Date()))
        socio.fechaBaja = form.fechaBaja ? formatISO(parse(form.fechaBaja, 'yyyy-MM-dd', new Date())) : null
        socio.fechaNacimiento = formatISO(parse(form.fechaNacimiento, 'yyyy-MM-dd', new Date()))
        socio.fechaIngresoLaboral = formatISO(parse(form.fechaIngresoLaboral, 'yyyy-MM-dd', new Date()))
        socio.legajo = Number(form.legajo)
        socio.telefono = Number(form.telefono)
        socio.cuil = Number(form.cuil)
        socio.numeroCuenta = Number(form.numeroCuenta) === 0 ? null : Number(form.numeroCuenta)
        socio.numeroDocumento = Number(form.numeroDocumento)
        socio.cuotaSocial = getCuotaSocial()?.toString()
        socio.cuotaSocialAdicional = form.cuotaSocialAdicional ? form.cuotaSocialAdicional : null
        socio.cuotaSalud = form.cuotaSalud ? form.cuotaSalud : null
        socio.cuotaDeporte = form.cuotaDeporte ? form.cuotaDeporte : null
        socio.credito = form.credito ? form.credito : null

        // necessary cause on window load it start checked but its value is undefined
        if (form.habilitado === undefined) {
            socio.habilitado = true
        }

        if (form.abonaCuotaSocial === undefined) {
            socio.abonaCuotaSocial = true
        }

        if (foto) {
            socio.foto = foto.data
        }

        socio.usuario = {
            ...socio.usuario,
            nombreUsuario: socio.usuario.nombreUsuario,
        }

        try {
            await api.post(`/socios/${action}`, socio)

            toast({title: `Socio ${status} correctamente!`, status: 'success'})
            navigate("/socios", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeAlreadyCreated) {
                setIsSaving(false)
                toast({
                    title: `El socio con número de documento ${socio.numeroDocumento} ya existe!`,
                    status: 'error'
                })

                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeEmailAlreadyRegistered) {
                setIsSaving(false)
                toast({
                    title: `Ya existe un registro guardado con el correo electronico ${socio.correo}!`,
                    status: 'error'
                })

                return
            }

            setIsSaving(false)
            toast({title: `El socio no pudo ser ${status}!`, status: 'error'})
        }
    }

    const videoConstraints = {
        width: 170,
        height: 170,
        facingMode: "user"
    };

    const WebcamCapture = () => {
        const webcamRef: any = useRef(null);
        const capture = useCallback(
            () => {
                const imageSrc = webcamRef.current.getScreenshot();

                const file = dataURLtoFile(imageSrc)
                setShowWebcam(!showWebcam)
                setSelectedFile({inputName: 'file', file: file})
                setFormState({
                    ...form,
                    foto: {
                        id: form.foto ? form.foto.id : '',
                        url: imageSrc,
                    }
                })
            },
            [webcamRef]
        );
        return (
            <>
                <Wrap borderRadius={'full'}>
                    <Webcam
                        audio={false}
                        width={400}
                        height={400}
                        ref={webcamRef}
                        screenshotQuality={1}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        mirrored={true}
                    />
                </Wrap>
                <HStack>
                    <Button onClick={capture} leftIcon={<FaCamera/>} colorScheme={"orange"}
                            size={"sm"}>Capturar</Button>
                    <Button onClick={() => setShowWebcam(!showWebcam)} leftIcon={<FaTimes/>} colorScheme={"gray"}
                            size={"sm"}>Cancelar</Button>
                </HStack>
            </>
        );
    };

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading>{id ? 'Editar socio' : 'Nuevo socio'}</Heading>
                <HStack>
                    <BackButton pathTo={'/socios'}/>
                </HStack>
            </HStack>
            <Divider my={5}/>

            <form autoComplete={'off'}>
                <Stack height={'60vh'} overflowY={'scroll'}>

                    {
                        showWebcam ?
                            <VStack
                                mb={1}
                                justifyContent={"space-around"}
                            >
                                <WebcamCapture/>
                            </VStack>
                            :
                            <VStack
                                mb={1}
                                justifyContent={"space-around"}
                            >
                                <Avatar
                                    boxShadow={'xl'}
                                    boxSize={'170px'}
                                    src={form.foto ? form.foto.url : ''}
                                    bg='gray.300'
                                />
                                <FormControl display={"none"}>
                                    <Input ref={photoInputRef}
                                           type={'file'}
                                           name={'file'}
                                           onChange={onFileInputChange}
                                           accept="image/*"
                                    />
                                </FormControl>
                                <HStack pt={3}>
                                    {/*<Button onClick={handlePhotoUpload} leftIcon={<FaCloudUploadAlt/>}
                                            colorScheme={"teal"}
                                            size={"sm"}>Subir</Button>
                                    <Button onClick={() => setShowWebcam(!showWebcam)} leftIcon={<FaCamera/>}
                                            colorScheme={"orange"} size={"sm"}>Tomar</Button>
                                    <Button onClick={handlePhotoDelete} leftIcon={<FaTrash/>} colorScheme={"red"}
                                            size={"sm"}>Borrar</Button>*/}
                                </HStack>
                            </VStack>

                    }

                    <HStack justify={"space-around"} mb={4}>
                        <VStack width={'30%'}>
                            <FormControl isInvalid={submitted && errors.nombre} isRequired>
                                <FormLabel>Nombre</FormLabel>
                                <Input
                                    name={'nombre'}
                                    placeholder={"Ingrese un nombre"}
                                    type='text'
                                    value={form.nombre || []}
                                    onChange={onInputChange}
                                    ref={nameRef}
                                />
                                {submitted && errors.nombre ? (
                                    <FormErrorMessage>{errors.nombre}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.apellido} isRequired>
                                <FormLabel>Apellido</FormLabel>
                                <Input
                                    name={'apellido'}
                                    placeholder={"Ingrese un apellido"}
                                    type='text'
                                    value={form.apellido || []}
                                    onChange={onInputChange}
                                    ref={lNameRef}
                                />
                                {submitted && errors.apellido ? (
                                    <FormErrorMessage>{errors.apellido}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.numeroDocumento} isRequired>
                                <FormLabel>Número documento</FormLabel>
                                <NumberInput
                                    name={'numeroDocumento'}
                                    value={form.numeroDocumento || []}
                                >
                                    <NumberInputField ref={docRef}
                                                      onChange={onInputChange}
                                                      maxLength={8}
                                                      placeholder={"Ingrese número de documento. ej: 33445566"}/>
                                </NumberInput>
                                {submitted && errors.numeroDocumento ? (
                                    <FormErrorMessage>{errors.numeroDocumento}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.fechaAlta} isRequired>
                                <FormLabel>Fecha de alta</FormLabel>
                                <Input
                                    name={'fechaAlta'}
                                    type='date'
                                    value={form.fechaAlta || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.fechaAlta ? (
                                    <FormErrorMessage>{errors.fechaAlta}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </VStack>

                        <VStack width={'30%'}>
                            <FormControl isInvalid={submitted && errors.telefono} isRequired>
                                <FormLabel>Teléfono</FormLabel>
                                <NumberInput
                                    name={'telefono'}
                                    value={form.telefono || []}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={"Ingrese un teléfono. ej: 2901334455"}/>
                                </NumberInput>

                                {submitted && errors.telefono ? (
                                    <FormErrorMessage>{errors.telefono}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.direccion} isRequired>
                                <FormLabel>Dirección</FormLabel>
                                <Input
                                    name={'direccion'}
                                    placeholder={"Ingrese una dirección"}
                                    type='text'
                                    value={form.direccion || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.direccion ? (
                                    <FormErrorMessage>{errors.direccion}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.localidad} isRequired>
                                <FormLabel>Localidad</FormLabel>
                                <Select name={'localidad'}
                                        placeholder='Seleccione una localidad'
                                        value={form.localidad}
                                        onChange={onInputChange}>

                                    {localidades ?
                                        localidades.map((localidad: string, i) => (
                                            <option key={i} value={localidad}>{localidad}</option>
                                        )) : []
                                    }
                                </Select>
                                {submitted && errors.localidad ? (
                                    <FormErrorMessage>{errors.localidad}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.fechaNacimiento} isRequired>
                                <FormLabel>Fecha de nacimiento</FormLabel>
                                <Input
                                    name={'fechaNacimiento'}
                                    type='date'
                                    value={form.fechaNacimiento || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.fechaNacimiento ? (
                                    <FormErrorMessage> {errors.fechaNacimiento}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </VStack>
                        <VStack width={'30%'}>
                            <FormControl isInvalid={submitted && errors.cuil} isRequired>
                                <FormLabel>Cuil</FormLabel>
                                <NumberInput
                                    name={'cuil'}
                                    value={form.cuil || []}
                                >
                                    <NumberInputField onChange={onInputChange} maxLength={11}
                                                      placeholder={"Ingrese un cuil. ej: 33445566778"}/>
                                </NumberInput>
                                {submitted && errors.cuil ? (
                                    <FormErrorMessage> {errors.cuil}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.estadoCivil} isRequired>
                                <FormLabel>Estado civil</FormLabel>
                                <Select name={'estadoCivil'}
                                        placeholder='Seleccione un estado civil'
                                        value={form.estadoCivil}
                                        onChange={onInputChange}
                                >
                                    {getEstadosCiviles().map((estadoCivil, i) => (
                                        <option key={i} value={estadoCivil}>{estadoCivil}</option>
                                    ))}
                                </Select>
                                {submitted && errors.estadoCivil ? (
                                    <FormErrorMessage> {errors.estadoCivil}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.correo} isRequired>
                                <FormLabel>Correo</FormLabel>
                                <Input
                                    name={'correo'}
                                    type='email'
                                    placeholder={'Ingrese un correo. ej: micorreo@mail.com'}
                                    value={form.correo || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.correo ? (
                                    <FormErrorMessage>{errors.correo}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </VStack>
                    </HStack>
                    <Box>
                        <Divider my={4}/>
                    </Box>
                    <HStack justify={"space-around"}>
                        <VStack width={'30%'} pb={'5rem'}>
                            <FormControl isInvalid={submitted && errors.legajo} isRequired>
                                <FormLabel>Legajo</FormLabel>
                                <NumberInput
                                    name={'legajo'}
                                    value={form.legajo || []}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={'Ingrese un legajo. ej: 3344556600'}/>
                                </NumberInput>
                                {submitted && errors.legajo ? (
                                    <FormErrorMessage>{errors.legajo}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.tipo} isRequired>
                                <FormLabel>Clase</FormLabel>
                                <Select name={'tipo'}
                                        placeholder='Seleccione una clase'
                                        value={form.tipo}
                                        onChange={onInputChange}
                                >
                                    <option value={'ACTIVO'}>ACTIVO</option>
                                    <option value={'ADHERENTE'}>ADHERENTE</option>
                                </Select>
                                {submitted && errors.tipo ? (
                                    <FormErrorMessage>{errors.tipo}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.fechaIngresoLaboral} isRequired>
                                <FormLabel>Fecha de ingreso</FormLabel>
                                <Input
                                    name={'fechaIngresoLaboral'}
                                    placeholder={"Ingrese la fecha de ingreso"}
                                    type='date'
                                    value={form.fechaIngresoLaboral || []}
                                    onChange={onInputChange}
                                />
                                {submitted && errors.fechaIngresoLaboral ? (
                                    <FormErrorMessage>{errors.fechaIngresoLaboral}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.numeroCuenta}>
                                <FormLabel>Número de cuenta</FormLabel>
                                <NumberInput name={'numeroCuenta'}
                                             value={form.numeroCuenta}
                                >
                                    <NumberInputField
                                        maxLength={9}
                                        onChange={onInputChange}
                                        placeholder={'Ingrese número de cuenta. ej: 667788'}/>
                                </NumberInput>
                                {submitted && errors.numeroCuenta ? (
                                    <FormErrorMessage>{errors.numeroCuenta}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.cbu}>
                                <FormLabel>CBU</FormLabel>
                                <NumberInput name={'cbu'}
                                             value={form.cbu || []}
                                >
                                    <NumberInputField onChange={onInputChange} maxLength={22}
                                                      placeholder={'Ingrese un cbu. ej: 1122334455667788990011'}/>
                                </NumberInput>
                                {submitted && errors.cbu ? (
                                    <FormErrorMessage>{errors.cbu} {form.cbu ? 22 - form.cbu.length : 22}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </VStack>
                        <VStack width={'30%'}>
                            <FormControl>
                                <FormLabel>Cuota social</FormLabel>
                                <NumberInput name={'cuotaSocial'}
                                             value={getCuotaSocial()?.toString()}
                                >
                                    <NumberInputField readOnly={true}/>
                                </NumberInput>
                            </FormControl>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor={'switchAbonaCuotaSocial'}>Abona cuota social</FormLabel>
                                <Box mt={-2}>
                                    <Switch id={'switchAbonaCuotaSocial'}
                                            name={'abonaCuotaSocial'}
                                            colorScheme={'blue'}
                                            defaultChecked={true}
                                            isChecked={form.abonaCuotaSocial}
                                            onChange={onInputChange}
                                    />
                                </Box>
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.credito}>
                                <FormLabel>Crédito</FormLabel>
                                <NumberInput
                                    min={1}
                                    name={'credito'}
                                    value={form.credito}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={'Ingrese un límite de crédito. ej: 30000'}/>
                                </NumberInput>
                                {submitted && errors.credito ? (
                                    <FormErrorMessage>{errors.credito}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.cuotaSocialAdicional}>
                                <FormLabel>Cuota social adicional</FormLabel>
                                <NumberInput
                                    min={1}
                                    max={10}
                                    name={'cuotaSocialAdicional'}
                                    value={form.cuotaSocialAdicional}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={'Ingrese una cantidad. ej: 2'}/>
                                </NumberInput>
                                {submitted && errors.cuotaSocialAdicional ? (
                                    <FormErrorMessage>{errors.cuotaSocialAdicional}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.cuotaSalud}>
                                <FormLabel>Cuota de salud</FormLabel>
                                <NumberInput
                                    min={1}
                                    name={'cuotaSalud'}
                                    value={form.cuotaSalud}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={'Ingrese una cuota de salud. ej: 400'}/>
                                </NumberInput>
                                {submitted && errors.cuotaSalud ? (
                                    <FormErrorMessage>{errors.cuotaSalud}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                            <FormControl isInvalid={submitted && errors.cuotaDeporte}>
                                <FormLabel>Cuota deportiva</FormLabel>
                                <NumberInput
                                    min={1}
                                    name={'cuotaDeporte'}
                                    value={form.cuotaDeporte}
                                >
                                    <NumberInputField onChange={onInputChange}
                                                      placeholder={'Ingrese una cuota deportiva. ej: 300'}/>
                                </NumberInput>
                                {submitted && errors.cuotaDeporte ? (
                                    <FormErrorMessage>{errors.cuotaDeporte}</FormErrorMessage>
                                ) : ([])}
                            </FormControl>
                        </VStack>
                        <VStack width={'30%'}>
                            <FormControl>
                                <FormLabel>Usuario</FormLabel>
                                <Input
                                    isReadOnly={true}
                                    name={'usuario.nombreUsuario'}
                                    type='text'
                                    placeholder='Usuario generado automáticamente'
                                    value={form.usuario ? form.usuario.nombreUsuario : ''}
                                    onChange={onInputChange}
                                />
                            </FormControl>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor={'switchHabilitado'}>Habilitado</FormLabel>
                                <Box mt={-2} ms={'1.32rem'}>
                                    <Switch id={'switchHabilitado'}
                                            name={'habilitado'}
                                            colorScheme={'teal'}
                                            defaultChecked={true}
                                            isChecked={form.habilitado}
                                            onChange={onInputChange}
                                    />
                                </Box>
                            </FormControl>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor={'switchExtranjero'}>Extranjero</FormLabel>
                                <Box mt={-2} ms={'1.32rem'}>
                                    <Switch id={'switchExtranjero'}
                                            name={'extranjero'}
                                            colorScheme={'blue'}
                                            defaultChecked={false}
                                            isChecked={form.extranjero}
                                            onChange={onInputChange}
                                    />
                                </Box>
                            </FormControl>
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor={'switchBaja'}>Dado de baja</FormLabel>
                                <Box mt={-2}>
                                    <Switch id={'switchBaja'}
                                            name={'baja'}
                                            colorScheme={'red'}
                                            defaultChecked={false}
                                            isChecked={form.baja}
                                            onChange={onInputChange}
                                    />
                                </Box>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Fecha de baja</FormLabel>
                                <Input
                                    name={'fechaBaja'}
                                    type='date'
                                    value={form.fechaBaja || []}
                                    onChange={onInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Observaciones</FormLabel>
                                <Textarea
                                    height={'120px'}
                                    onChange={onInputChange}
                                    name={'observaciones'}
                                    value={form.observaciones}
                                    placeholder='Puede ingresar observaciones del socio'
                                    resize={'vertical'}
                                />
                            </FormControl>

                        </VStack>
                    </HStack>

                    <Box>
                        <Divider my={4}/>
                    </Box>

                    <Stack mx={'1.5%'}>
                        <HStack justify={'space-between'}>
                            <Heading size={'lg'}>Familiares</Heading>
                            <Button onClick={handleOnAddFamiliar} type={'button'} leftIcon={<FaPlus/>}
                                    colorScheme={'green'}>
                                Familiar
                            </Button>
                        </HStack>
                        {form.familiares ?
                            form.familiares.map((familiar: Familiar) => (
                                <HStack justify={'space-around'} key={familiar.id}>
                                    <VStack width={'30%'}>
                                        <FormControl isInvalid={submitted && familiar.nombre === ''} isRequired>
                                            <FormLabel>Nombre</FormLabel>
                                            <Input
                                                name={`familiares[${familiar.id}].nombre`}
                                                placeholder={"Ingrese un nombre"}
                                                type='text'
                                                value={familiar.nombre}
                                                onChange={onInputChange}
                                            />
                                            {submitted && familiar.nombre === '' ? (
                                                <FormErrorMessage>Debe ingresar un nombre</FormErrorMessage>
                                            ) : ([])}
                                        </FormControl>
                                    </VStack>
                                    <VStack width={'30%'}>
                                        <FormControl isInvalid={submitted && familiar.apellido === ''} isRequired>
                                            <FormLabel>Apellido</FormLabel>
                                            <Input
                                                name={`familiares[${familiar.id}].apellido`}
                                                placeholder={"Ingrese un apellido"}
                                                type='text'
                                                value={familiar.apellido}
                                                onChange={onInputChange}
                                            />
                                            {submitted && familiar.apellido === '' ? (
                                                <FormErrorMessage>Debe ingresar un apellido</FormErrorMessage>
                                            ) : ([])}
                                        </FormControl>
                                    </VStack>
                                    <VStack width={'30%'}>
                                        <FormControl isInvalid={submitted && familiar.parentezco === ''} isRequired>
                                            <FormLabel>Parentezco</FormLabel>
                                            <Select name={`familiares[${familiar.id}].parentezco`}
                                                    placeholder='Seleccione un parentezco'
                                                    value={familiar.parentezco}
                                                    onChange={onInputChange}
                                            >
                                                <option value={'PADRE'}>PADRE</option>
                                                <option value={'MADRE'}>MADRE</option>
                                                <option value={'HERMANO'}>HERMANO</option>
                                                <option value={'HIJO'}>HIJO</option>
                                                <option value={'PAREJA'}>PAREJA</option>
                                                <option value={'OTRO'}>OTRO</option>
                                            </Select>
                                            {submitted && familiar.parentezco === '' ? (
                                                <FormErrorMessage>Debe seleccionar un parentezco</FormErrorMessage>
                                            ) : ([])}
                                        </FormControl>
                                    </VStack>
                                    <VStack>
                                        <IconButton mt={8}
                                                    mx={1}
                                                    colorScheme={'red'}
                                                    aria-label={`borrar ${familiar.id}`}
                                                    icon={<FaTrash/>}
                                                    onClick={() => handleDeleteFamiliar(familiar.id)}/>
                                    </VStack>
                                </HStack>
                            )) : []
                        }
                    </Stack>
                </Stack>


                <HStack justify={'flex-end'}>
                    <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                </HStack>
            </form>
        </>
    );
}

// only for dev
const testSocio = {
    nombre: 'Mariano',
    apellido: 'Fuhr',
    cuil: 20233333339,
    direccion: 'mi dir 123',
    fechaIngresoLaboral: '2012-05-13',
    fechaNacimiento: '1991-04-22',
    sexo: 'HOMBRE',
    foto: {
        url: '',
    },
    fechaAlta: '',
    estadoCivil: 'SOLTERO',
    legajo: 123456789,
    localidad: 'USHUAIA',
    correo: 'mfuhr91@gmail.com',
    cbu: '9783323654646789231445',
    tipo: 'ACTIVO',
    estadoCuenta: '6200',
    escalafon: `{"id":"c1b3484a6189414089d2969efd0e7a42","nombre":"SECO","fechaAlta":"2022-10-26T03:00:00Z"}`,
    reparticion: `{"id":"38e7462d3cbc48a7ae9427169d73d4a4","nombre":"CASA CENTRAL","fechaAlta":"2022-10-26T03:00:00Z"}`,
    numeroCuenta: 12345456,
    numeroDocumento: 35356600,
    telefono: 2901234444,
    usuario: {nombreUsuario: 'mfuhr91@gmail.com'},
    familiares: [],
    profesion: 'Abogado',
}