import {useFetch} from "../../../hooks/useFetch";
import {CustomComprasHeading} from "../components/CustomComprasHeading";
import {useEffect, useRef, useState} from "react";
import {
    Badge,
    HStack,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    Wrap,
    WrapItem
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {FaFileInvoice, FaInfoCircle, FaPenAlt, FaTrash} from "react-icons/fa";
import {format, parseISO} from "date-fns";
import es from "date-fns/locale/es"
import {deleteObjectByID} from "../../../helpers/deleteObjectByID";
import {Compra} from "../../../interfaces/Compra";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {findComprasByParam} from "../../../helpers/findComprasByParam";
import {api} from "../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {isComercio} from "../../../store/auth/auth";
import {DeleteConfirm} from "../../../components/DeleteConfirm";
import {getNroCompra, isAutomaticCharge} from "../helpers/getNroCompra";
import {useDownloadFile} from "../../../hooks/useDownloadFile";
import {TableSkeleton} from "../../../components/TableSkeleton";
import {setPrevMonth} from "../../../store/storeSlice";

export const Compras = () => {
    const {rol, comercioLogged, prevMonth, lastComprasPath} = useSelector((state: any) => state.store)
    const [isFromComprasModule] =
        useState(lastComprasPath.includes("compras/nuevo") || lastComprasPath.includes("compras/editar"))
    const [date, setDate] = useState(isFromComprasModule && prevMonth ? prevMonth : format(new Date(), 'yyyy-MM-dd'))
    const [month, setMonth] = useState(new Date())
    const {data, isLoading, setIsLoading} = useFetch(isComercio(rol) ?
        `/compras/all_month/${date}?comercio_id=${comercioLogged?.id}` :
        `/compras/all_month/${date}`)

    const [list, setList] = useState(data)
    const [initialList, setInitialList] = useState(data)
    const toast = useCustomToast({})
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef: any = useRef()
    const [infoToDelete, setInfoToDelete] = useState({id: '', description: ''})
    const {downloadFile, isDownloading} = useDownloadFile({})
    const [btnId, setBtnId] = useState('')
    const dispach = useDispatch()

    const getTotalCompras = () => {
        let total = 0

        if (!list) {
            return 0
        }

        list.map((compra: Compra) => {
            let monto = Number(compra.valorTotal)
            if (isComercio(rol)) {
                monto = Number(compra.valor)
            }

            return total += monto
        })

        return total.toFixed(2)
    }

    const handleOnExportPDF = async (id: string, event: any) => {
        setBtnId(event.currentTarget.id)

        await downloadFile(`/compras/export_pdf/${id}`)
    }

    const handleOnChangeMonth = async ({target}: any) => {
        setList([])
        setInitialList([])

        let {value} = target

        //cannot be 01 of month becouse it changes the month input value to the last month
        value = value + "-02"

        dispach(setPrevMonth(value))

        setDate(value)
        try {
            setIsLoading(true)
            const res = await api.get(isComercio(rol) ?
                `/compras/all_month/${value}?comercio_id=${comercioLogged?.id}` :
                `/compras/all_month/${value}`)
            if (res.status !== 200) {
                toast({title: `No se pudo obtener la lista de compras`, status: 'error'})
                setList([])
                return
            }
            setIsLoading(false)
            setList(res.data)
            setInitialList(res.data)

        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }
            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo obtener la lista de compras`, status: 'error'})
            }
        }
    }

    const handleDeleteCompra = async (id: string) => {
        try {
            await deleteObjectByID("compras", id)
            setList(list.filter((value: Compra) => value.id !== id))
            setInitialList(initialList.filter((value: Compra) => value.id !== id))

            try {
                await api.post(`/save_log`, {"Code": 200, "Msg": "compra successfully deleted in frontend"})
            } catch (error: any) {
                console.error("error trying to send log to backend", error)
            }

            if (!toast.isActive("custom-toast")) {
                toast({title: "Compra eliminada con éxito!", status: "success"})
            }
            onClose()
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            try {
                await api.post(`/save_log`, {
                    "Code": error.response ? error.response.status : 0,
                    "Msg": `cannot delete compra ${id}  in frontend - ${JSON.stringify(error.response ? error.response.data : "no data")}`
                })
            } catch (error: any) {
                console.error("error trying to send log to backend", error)
            }

            if (!toast.isActive("custom-toast")) {
                toast({title: "La compra no pudo ser eliminada!", status: "error"})
            }

        }
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setList(findComprasByParam(initialList, value))
    }

    useEffect(() => {
        setList(data)
        setInitialList(data)

    }, [data]);

    useEffect(() => {
        // need for correctly select the month
        const newDate = date.slice(0, 7) + "-02"
        const prev = prevMonth?.slice(0, 7) + "-02"
        setMonth(new Date(isFromComprasModule && prevMonth ? prev : newDate))

    }, [data, date]);

    return (
        <>

            <CustomComprasHeading title={'Compras'}
                                  data={list}
                                  month={format(new Date(
                                      isFromComprasModule && prevMonth ?
                                          prevMonth :
                                          month), "yyyy-MM")}
                                  findParam={handleFindParam}
                                  onChangeMonth={handleOnChangeMonth}/>

            <TableContainer overflowY={'scroll'} maxH={'63vh'}>
                <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                    <Thead>
                        <Tr>
                            <Th>Nro</Th>
                            <Th>Descripción</Th>
                            <Th px={0}>Cuotas</Th>
                            <Th>Fecha de compra</Th>
                            <Th>Socio</Th>
                            <Th>Comercio</Th>
                            {
                                !isComercio(rol) ?
                                    <>
                                        <Th>Cuota</Th>
                                        <Th>Monto</Th>
                                        <Th>Cuota + %</Th>
                                        <Th>Monto + %</Th>
                                    </>
                                    :
                                    <Th>Monto</Th>

                            }

                            <Th>Última cuota</Th>
                            <Th>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {list ?
                            list.map((compra: Compra) => (
                                <Tr key={compra.id}>
                                    <Td maxWidth={'6rem'}>
                                        <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                            {isAutomaticCharge(compra) ? '-/' + compra.anio : getNroCompra(compra)}
                                        </Badge>
                                    </Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'10rem'}>{compra.descripcion}</Td>
                                    <Td isNumeric>{compra.numeroCuotas}
                                    </Td>
                                    <Td>
                                        {format(parseISO(compra.fecha), 'dd-MM-yyyy HH:mm')}
                                    </Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'11rem'}>
                                        {compra.socio.apellido + ' ' + compra.socio.nombre}</Td>
                                    <Td textOverflow={'ellipsis'} overflowX={'hidden'}
                                        maxWidth={'10rem'}>
                                        {compra.comercio.nombre}
                                    </Td>
                                    {
                                        isComercio(rol) ?
                                            []
                                            :
                                            <>
                                                <Td>
                                                    <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                                        ${Number(compra.valor / compra.cuotas.length).toFixed(2)}
                                                    </Badge>
                                                </Td>
                                                <Td>
                                                    <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                                        ${Number(compra.valor).toFixed(2)}
                                                    </Badge>
                                                </Td>
                                                <Td>
                                                    <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                                        ${Number(compra.valorCuota).toFixed(2)}
                                                    </Badge>
                                                </Td>
                                            </>
                                    }
                                    <Td>
                                        {
                                            isComercio(rol) ?
                                                <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                                    ${Number(compra.valor).toFixed(2)}
                                                </Badge>
                                                :
                                                <Badge fontSize={13} variant='subtle' colorScheme={'blackAlpha'}>
                                                    ${Number(compra.valorTotal).toFixed(2)}
                                                </Badge>
                                        }
                                    </Td>
                                    <Td maxWidth={'7rem'}>
                                        {format(parseISO(compra.ultimaCuota), 'MMMM-yyyy', {locale: es}).toUpperCase()}
                                    </Td>

                                    <Td>
                                        {
                                            isComercio(rol) ?
                                                <>
                                                    <IconButton isLoading={compra.id === btnId ? isDownloading : false}
                                                                id={`pdf-${compra.id}`}
                                                                size={'sm'}
                                                                onClick={(target: any) => handleOnExportPDF(compra.id, target.id)}
                                                                mx={1}
                                                                colorScheme={'telegram'} aria-label={`pdf ${compra.id}`}
                                                                icon={<FaFileInvoice/>}/>

                                                    <IconButton size={'sm'} onClick={() => {
                                                        onOpen()
                                                        setInfoToDelete({
                                                            id: compra.id,
                                                            description: compra.descripcion
                                                        })
                                                    }}
                                                                mx={1}
                                                                colorScheme={'red'} aria-label={`borrar ${compra.id}`}
                                                                icon={<FaTrash/>}/>
                                                </>

                                                :
                                                <>
                                                    <IconButton isLoading={compra.id === btnId ? isDownloading : false}
                                                                size={'sm'}
                                                                id={compra.id}
                                                                onClick={(event) => handleOnExportPDF(compra.id, event)}
                                                                mx={1}
                                                                colorScheme={'telegram'} aria-label={`pdf ${compra.id}`}
                                                                icon={<FaFileInvoice/>}/>
                                                    {
                                                        compra.nro === 0 ?
                                                            <Tooltip borderRadius={8} mr={2} label={
                                                                <HStack><FaInfoCircle fontSize={'35px'}/>
                                                                    <span>
                                                                        No se puede editar una cuota generada
                                                                        automáticamente por el sistema.
                                                                    </span>
                                                                </HStack>}
                                                                     aria-label='usuarioTooltip'>
                                                                <IconButton
                                                                    size={'sm'}
                                                                    type={'button'}
                                                                    mx={1}
                                                                    colorScheme={'orange'}
                                                                    aria-label={`info ${compra.id}`}
                                                                    icon={<FaInfoCircle/>}/>
                                                            </Tooltip>
                                                            :
                                                            <Link to={`/compras/editar/${compra.id}`}>
                                                                <IconButton size={'sm'} mx={1} colorScheme={'yellow'}
                                                                            aria-label={`editar ${compra.id}`}
                                                                            icon={<FaPenAlt/>}/>
                                                            </Link>
                                                    }
                                                    <IconButton size={'sm'} onClick={() => {
                                                        onOpen()
                                                        setInfoToDelete({
                                                            id: compra.id,
                                                            description: compra.descripcion
                                                        })
                                                    }}
                                                                mx={1}
                                                                colorScheme={'red'} aria-label={`borrar ${compra.id}`}
                                                                icon={<FaTrash/>}/>
                                                </>
                                        }
                                    </Td>
                                </Tr>
                            )) : []
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <TableSkeleton isLoading={isLoading}/>
            <Wrap justify={'center'} my={4}>
                <WrapItem>
                    <Badge colorScheme={'purple'}>
                        Compras de {format(month, 'MMMM', {locale: es})}: {list ? list.length : 0}
                    </Badge>
                </WrapItem>
                <WrapItem>
                    <Badge colorScheme={'teal'}>
                        Monto total: ${getTotalCompras()}
                    </Badge>
                </WrapItem>
            </Wrap>
            <DeleteConfirm isOpen={isOpen}
                           onClose={onClose}
                           pron={'la'}
                           entity={'compra'}
                           id={infoToDelete.id}
                           description={infoToDelete.description}
                           onDeleteConfirm={handleDeleteCompra}
                           cancelRef={cancelRef}
            />
        </>
    );
}
