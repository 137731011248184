import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    NumberInput,
    NumberInputField,
    Stack, Switch,
    VStack
} from "@chakra-ui/react";

import {useEffect, useState} from "react";
import {useForm} from "../../../hooks/useForm";
import {useNavigate, useParams} from "react-router-dom";
import {validations} from "../helpers/validations";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {Variable} from "../../../interfaces/Variable";
import {SaveButton} from "../../../components/SaveButton";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {api} from "../../../api/api";
import {getFormat, getMax, getMin, getPrecision, isClosingDate} from "../helpers/utils";
import {BackButton} from "../../../components/BackButton";


export const FormVariable = () => {
    const {type} = useParams();
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const {object}: any =
        useGetObjectByID('variables/get', type, 'la', 'variable')
    const {form, onInputChange, setFormState, errors} = useForm({validations})

    const translateType = (type: string) => {
        switch (type) {
            case 'DiaCierreMes':
                return 'DÍA DE CIERRE'
            case 'ValorMaxItem':
                return 'VALOR MÁXIMO DE ITEM DE DESCUENTO'
            case 'ValorMaxDescuento':
                return 'VALOR MÁXIMO DE CUOTA'
            case 'CuotaSocial':
                return 'CUOTA SOCIAL'
            case 'Comision':
                return 'COMISIÓN'
            case 'LimiteCredito':
                return 'LÍMITE DE CRÉDITO'
        }
    }

    const handleOnChangeValor = (value: any) => {
        setFormState({
            ...form,
            valor: value,
            validations
        })
    }

    const handleOnChangeActualizarMesActual = ({target}: any) => {
        const {checked} = target
        setFormState({
            ...form,
            actualizarMesActual: checked,
            validations
        })

        console.log(form)
    }

    useEffect(() => {
        if (object.id) {
            setFormState({
                ...object,
                valor: !isClosingDate(object.tipo) ? Number(object.valor).toFixed(2) : object.valor,
                validations
            })
        }
    }, [object]);

    useEffect(() => {

    }, [form]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitted(true)

        if (errors.length !== 0) {
            return
        }
        setIsSaving(true)
        let variable: Variable = {...form}

        try {
            await api.post(`/variables/update`, variable)

            toast({title: `Variable actualizada correctamente!`, status: 'success'})
            navigate("/variables", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        id: "custom-toast",
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            setIsSaving(false)
            toast({title: `La variable no pudo ser actualizada!`, status: 'error'})
        }
    }

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading>Editar variable</Heading>
                <HStack>
                    <BackButton pathTo={'/variables'}/>
                </HStack>
            </HStack>
            <Divider my={5}/>

            <form>

                <VStack height={'60vh'} overflowY={'scroll'} w={'80%'} align={'start'} justify={'start'} ml={5} mb={4}>
                    <VStack width={'30%'}>
                        <FormControl>
                            <FormLabel>Tipo</FormLabel>
                            <Input
                                isReadOnly
                                name={'tipo'}
                                type='text'
                                value={translateType(form.tipo) || []}
                            />
                        </FormControl>
                    </VStack>
                    <HStack width={'30%'}>
                        <FormControl isInvalid={submitted && errors.valor} isRequired>
                            <FormLabel>Valor</FormLabel>
                            <NumberInput
                                onChange={handleOnChangeValor}
                                value={form.valor ? getFormat(form.tipo, form.valor) : ''}
                                precision={getPrecision(form.tipo)}
                                max={getMax(form.tipo)}
                                min={getMin(form.tipo)}
                                name={'valor'}
                            >
                                <NumberInputField onChange={onInputChange}
                                                  placeholder={'Ingrese un valor'}/>
                            </NumberInput>
                            {submitted && errors.valor ? (
                                <FormErrorMessage>{errors.valor}</FormErrorMessage>
                            ) : ([])}
                        </FormControl>
                    </HStack>
                    {
                        form.tipo === "CuotaSocial" ?
                            <>
                                <VStack width={'30%'}>

                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel htmlFor={'switchActualizarMesActual'}>Actualizar mes actual?</FormLabel>
                                        <Box mt={-2}>
                                            <Switch id={'switchActualizarMesActual'}
                                                    name={'actualizarMesActual'}
                                                    colorScheme={'blue'}
                                                    defaultChecked={false}
                                                    isChecked={form.actualizarMesActual}
                                                    onChange={handleOnChangeActualizarMesActual}
                                            />
                                        </Box>
                                    </FormControl>
                                    {/*<FormControl>
                                        <FormLabel>Actualizar mes actual?</FormLabel>
                                        <Checkbox
                                            onChange={handleOnChangeValor}
                                            value={form.actualizarMesActual}
                                            name={'actualizarMesActual'}
                                            size='lg'
                                            colorScheme='blue'
                                        >
                                        </Checkbox>
                                    </FormControl>*/}
                                </VStack>
                            </>
                            : []
                    }
                </VStack>
                <HStack justify={'flex-end'}>
                    <SaveButton isLoading={isSaving} onSubmit={onSubmit}/>
                </HStack>
            </form>
        </>
    )
}
