import {useEffect, useState} from "react";
import {capitalize} from "../helpers/capitalize";
import {fakePassword} from "../pages/usuarios/pages/FormUsuario";
import {format} from "date-fns";

export const useForm = (formValue: any) => {
    const [form, setFormState] = useState(formValue);
    const [errors, setErrors] = useState([])

    const onInputChange = ({target}: any) => {
        const {name, value} = target;

        if ((name === 'habilitado') ||
            (name === 'extranjero') ||
            (name === 'abonaCuotaSocial') ||
            (name === 'hasCommission')) {
            const {checked} = target
            setFormState({
                ...form,
                [name]: checked
            })
            return
        }

        if (name === 'baja') {
            const {checked} = target
            if (checked) {
                setFormState({
                    ...form,
                    [name]: checked,
                    fechaBaja:format(new Date(), 'yyyy-MM-dd'),
                })
                return
            }

            setFormState({
                ...form,
                [name]: checked,
                fechaBaja: null,
            })

            return
        }

        if (name === 'correo') {
            setFormState({
                ...form,
                usuario: {
                    ...form.usuario,
                    nombreUsuario: value,

                },
                correo: value,
            })
            return
        }

        if (name === 'nombreUsuario') {
            console.log(value)
            setFormState({
                ...form,
                [name]: value.toLowerCase()
            })

            return;
        }

        if ((name === 'contrasena') ||
            (name === 'contrasenaCheck') ||
            (name === 'descripcion')) {

            setFormState({
                ...form,
                [name]: value
            })
            return
        }

        if (name.includes('familiares')) {
            const id = name.split("[")[1].split("].")[0]
            const inputName = name.split("[")[1].split("].")[1]

            let newFamiliares: any = []
            for (const familiar of form.familiares) {
                if (familiar.id === id) {
                    newFamiliares = [
                        ...newFamiliares,
                        {
                            ...familiar,
                            [inputName]: capitalize(value)
                        }
                    ]
                    continue
                }
                newFamiliares = [...newFamiliares, familiar]
            }

            setFormState({
                ...form,
                familiares: newFamiliares
            })

            return
        }

        if (name === 'categoria') {
            setFormState({
                ...form,
                [name]: value
            })
            return
        }


        setFormState({
            ...form,
            [name]: capitalize(value)
        })

    }


    useEffect(() => {

        if (form.validations) {

            let valid = true;
            const newErrors: any = {};

            for (const key in form.validations) {
                const value = form[key];
                const validation = form.validations[key];
                if (validation?.required?.value && !value) {
                    valid = false;
                    newErrors[key] = validation?.required?.message;
                    continue
                }

                const pattern = validation?.pattern;
                if (validation?.pattern?.value && !RegExp(pattern.value).test(value)) {
                    if (value.toString() === fakePassword) {
                        continue
                    }
                    valid = false;
                    newErrors[key] = pattern.message;
                    continue
                }

                const custom = validation?.custom;
                if (value && custom?.isValid && !custom.isValid(value.toString())) {
                    valid = false;
                    newErrors[key] = custom.message;
                }
            }

            setErrors(newErrors);
            if (valid) {
                setErrors([]);
            }
        }
    }, [form]);


    return {
        ...form,
        form,
        onInputChange,
        setFormState,
        errors
    }
}