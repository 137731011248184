import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    NumberInput,
    NumberInputField,
    Stack,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tooltip,
    Tr,
    Wrap,
    WrapItem
} from "@chakra-ui/react";

import {useEffect, useState} from "react";
import {FaInfoCircle, FaKey, FaMinus, FaPlus, FaSearch, FaTimesCircle} from "react-icons/fa";
import {useForm} from "../../../hooks/useForm";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {formatISO} from 'date-fns'
import {validations} from "../helpers/validations";
import {useCustomToast} from "../../../hooks/useCustomToast";
import {Comercio} from "../../../interfaces/Comercio";
import {Compra} from "../../../interfaces/Compra";
import {useFetch} from "../../../hooks/useFetch";
import {findByParam} from "../../../helpers/findByParam";
import {useGetObjectByID} from "../../../hooks/useGetObjectByID";
import {api} from "../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {isComercio} from "../../../store/auth/auth";
import {errorCodeDisabledToBuy, errorCodeNoCredit, errorCodeNotFound} from "../../../utils/constants";
import {getQuotesNumber} from "../../../helpers/getQuotesNumber";
import {BackButton} from "../../../components/BackButton";
import {CodeModal} from "../components/CodeModal";
import {getNroCompra} from "../helpers/getNroCompra";
import {TableSkeleton} from "../../../components/TableSkeleton";
import {setLastComprasPath} from "../../../store/storeSlice";


export const FormCompra = () => {
    const {id} = useParams();
    const {rol, comercioLogged} = useSelector((state: any) => state.store)
    const toast = useCustomToast({})
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const today = formatISO(new Date()).slice(0, 16)
    const {data, isLoading} = useFetch("/comercios/all")
    const [comerciosList, setComerciosList] = useState(isComercio(rol) ? [comercioLogged] : data)
    const [initialComerciosList, setInitialComerciosList] = useState(isComercio(rol) ? [comercioLogged] : data)
    const [showCodeModal, setShowCodeModal] = useState(false)
    const [comercio, setComercio] = useState(isComercio(rol) ? {...comercioLogged} : {nombre: '', cuit: 0})
    const [comercioSelected, setComercioSelected] = useState(isComercio(rol) ? {...comercioLogged} : '')
    const [socio, setSocio] = useState({id: '', nombre: '', apellido: '', numeroDocumento: 0})
    const [isDocComplete, setIsDocComplete] = useState(false)
    const [isSearchingSocio, setIsSearchingSocio] = useState(false)
    const [code, setCode] = useState('')
    const [cantCuotas, setCantCuotas] = useState([1])
    const [quoteSelected, setQuoteSelected] = useState(1)
    const [accordionOpened] = useState(isComercio(rol) ?
        [1] : id ? [2] : [0])
    const {object}: any =
        useGetObjectByID('compras/get', id, 'la', 'compra')

    const {form, onInputChange, setFormState, errors} = useForm({validations, fecha: today, numeroCuotas: 1})

    const handleCloseModal = () => {
        setShowCodeModal(false)
    }

    const dispach = useDispatch()
    const location = useLocation()


    dispach(setLastComprasPath(location.pathname))

    const sendCode = async (event: any) => {

        event.preventDefault();
        setSubmitted(true)

        if (errors.length !== 0
            || comercio.cuit === 0
            || (socio.numeroDocumento === undefined ||
                socio.numeroDocumento === null ||
                socio.numeroDocumento === 0)) {
            return
        }

        setShowCodeModal(true)

        /*try {
            // TODO: send only socio when all is test by AMPS
            const res = await api.post(`/socios/send_code`,
                {
                    "IsAdmin": !isComercio(rol),
                    "Socio": socio
                }
            )
            if (!isComercio(rol)) {
                setCode(res.data)
            }
        } catch (error: any) {
            if (error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({
                    title: `No se pudo solicitar el código de confirmación de compra`,
                    status: 'error'
                })
            }
        }*/
    }

    const handleOnChangeQuote = ({target}: any) => {
        const {value} = target
        setQuoteSelected(value)
        setFormState({
            ...form,
            numeroCuotas: value
        })
    }


    const montoFormat = (val: any) => `$` + val
    const montoParse = (val: any) => val.replace(/^\$/, '')

    const handleOnChangeValor = (value: any) => {
        setFormState({
            ...form,
            valor: montoParse(value)
        })
    }

    const handleOnChangeDoc = ({target}: any) => {
        const {value} = target
        setIsDocComplete(false)
        if (value.length === 8) {
            setIsDocComplete(true)
        }
        setFormState({
            ...form,
            socio: {
                ...form.socio,
                numeroDocumento: value
            }
        })
    }

    const handleComercioSelect = (comercio: Comercio) => {
        setComercio({...comercio})
        setMaxCantCuotas(comercio)
        setComercioSelected(comercio.id)
    }

    const handleFindParam = ({target}: any) => {
        const {value} = target
        setComerciosList(findByParam(initialComerciosList, value))
    }

    useEffect(() => {
        setComerciosList(isComercio(rol) ? [comercioLogged] : data)
        setInitialComerciosList(isComercio(rol) ? [{comercioLogged}] : data)
        setFormState({
            ...form,
        })
    }, [data]);

    useEffect(() => {
        if (object.id) {
            object.fecha = formatISO(new Date(object.fecha)).slice(0, 16)
            object.hasCommission = true
            setSocio({...object.socio})
            setComercio({...object.comercio})
            setQuoteSelected(object.numeroCuotas)
            setComercioSelected(object.comercio.id)
            setMaxCantCuotas(object.comercio)
            setFormState({
                ...object,
                validations
            })

            return
        }

        setQuoteSelected(1)
    }, [object]);

    useEffect(() => {
        if (comercioLogged) {
            setMaxCantCuotas(comercioLogged)
        }

        setSocio({...form.socio})
    }, [])

    const handleOnSearchSocio = async () => {
        if (!isDocComplete) {
            return
        }

        setIsSearchingSocio(true)

        try {
            const res = await api.get(`/socios/by_doc/${form.socio.numeroDocumento}`)
            setSocio(res.data)
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            setIsSearchingSocio(false)

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeNotFound) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `No se encontró un socio registrado con el número de documento ingresado!`,
                        status: 'warning'
                    })
                }
                setSocio({id: '', nombre: '', apellido: '', numeroDocumento: 0})
                return
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeDisabledToBuy) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `El socio se encuentra inhabilitado para realizar compras!`,
                        status: 'warning'
                    })
                }
                setSocio({id: '', nombre: '', apellido: '', numeroDocumento: 0})
                return
            }

            if (!toast.isActive("custom-toast")) {
                toast({title: `El socio no pudo ser encontrado!`, status: 'error'})
            }

            setSocio({id: '', nombre: '', apellido: '', numeroDocumento: 0})
        }

        setIsSearchingSocio(false)
    }

    const setMaxCantCuotas = (comercio: Comercio) => {
        let arr: number[] = []
        for (const item of getQuotesNumber()) {
            arr = [...arr, item]
            if (item === Number(comercio.maximoCuotas)) {
                break
            }
        }
        setCantCuotas(arr)
    }

    const onSubmit = async (event: any) => {
        setIsSaving(true)
        let compra: Compra = {...form}

        compra.fecha = formatISO(new Date(form.fecha))
        compra.numeroCuotas = Number(form.numeroCuotas)
        compra.comercio = {
            ...compra.comercio,
            ...comercio,
        }
        compra.socio = {
            ...compra.socio,
            ...socio,
            numeroDocumento: Number(form.socio.numeroDocumento)
        }

        // necessary cause on window load it start checked but its value is undefined
        if (form.hasCommission === undefined) {
            compra.hasCommission = true
        }

        if (!isComercio(rol)) {
            compra.fromAdmin = true
        }

        let action = 'save'
        let status = 'registrada'
        if (id) {
            action = 'update'
            status = 'actualizada'
        }

        try {
            await api.post(`/compras/${action}`, compra)

            try {
                await api.post(`/save_log`, {"Code":200, "Msg": "compra successfully saved in frontend"})
            } catch (error: any) {
                console.error("error trying to send log to backend ", error)
            }

            toast({title: `Compra ${status} correctamente!`, status: 'success'})
            navigate("/compras", {replace: true})
        } catch (error: any) {
            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            try {
                await api.post(`/save_log`, {
                    "Code":error.response.status,
                    "Msg": `cannot save compra in frontend - ${JSON.stringify(error.response.data)}`
                })
            } catch (error: any) {
                console.error("error trying to send log to backend", error)
            }

            if (error.response &&
                error.response.data &&
                error.response.data.Code === errorCodeNoCredit) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `El socio no tiene crédito disponible para realizar esta compra! Intente aumentando la cantidad de cuotas.`,
                        status: 'warning'
                    })
                }
                setIsSaving(false)
                return
            }

            setIsSaving(false)
            toast({title: `La compra no pudo ser ${status}!`, status: 'error'})
        }
    }

    return (
        <>
            <HStack justifyContent={"space-between"}>
                <Heading>{id && form.nro ? `Editar compra - Nro. ${getNroCompra(form)}` : 'Nueva compra'}</Heading>
                <HStack>
                    <BackButton pathTo={'/compras'}/>
                </HStack>
            </HStack>
            <Divider my={5}/>

            <form autoComplete={'off'}>
                <Stack height={'60vh'} overflowY={'scroll'}>
                    <Accordion defaultIndex={accordionOpened}>
                        <AccordionItem>
                            {({isExpanded}) => (
                                <>
                                    <AccordionButton>
                                        <Box flex='1' textAlign='left'>
                                            <Box fontSize={'2xl'}>
                                                {comercio.cuit ?
                                                    <Wrap>
                                                        <WrapItem>
                                                            {comercio.cuit}
                                                        </WrapItem>
                                                        <WrapItem>
                                                            {comercio.nombre}
                                                        </WrapItem>
                                                    </Wrap>

                                                    : 'Seleccione un comercio'}
                                                {submitted && comercio.cuit === 0 ? (
                                                    <Badge borderRadius={5} p={2} mx={2} mb={1} variant={'solid'}
                                                           colorScheme={'red'}>
                                                        <HStack px={2}>
                                                            <FaTimesCircle/>
                                                            <Box>
                                                                Debe seleccionar un comercio!
                                                            </Box>
                                                        </HStack>
                                                    </Badge>
                                                ) : ([])}
                                            </Box>
                                        </Box>
                                        {
                                            isComercio(rol) ?
                                                [] :
                                                <>
                                                    {isExpanded ? (
                                                        <FaMinus fontSize='18px'/>
                                                    ) : (
                                                        <FaPlus fontSize='18px'/>
                                                    )}
                                                </>
                                        }
                                    </AccordionButton>
                                    {
                                        isComercio(rol) ?
                                            [] :
                                            <>
                                                <AccordionPanel pb={4}>

                                                    <InputGroup mb={2}>
                                                        <InputLeftElement
                                                            zIndex={0}
                                                            pointerEvents='none'
                                                            children={<FaSearch color={'lightgray'}/>}
                                                        />
                                                        <Input placeholder={'Buscar'} onChange={handleFindParam}/>
                                                    </InputGroup>
                                                    <TableContainer overflowY={'scroll'}
                                                                    maxH={'30vh'}
                                                                    border={submitted && comercio.cuit === 0 ? 'solid' : ''}
                                                                    borderColor={submitted && comercio.cuit === 0 ? 'red' : ''}
                                                                    borderRadius={10}>
                                                        <Table size={'sm'} variant={'striped'} colorScheme={'gray'}>
                                                            <Thead>
                                                                <Tr>
                                                                    <Th>Seleccionar</Th>
                                                                    <Th>Cuit</Th>
                                                                    <Th>Nombre</Th>
                                                                    <Th>Contacto</Th>
                                                                    <Th>Teléfono</Th>
                                                                    <Th>Direccion</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {comerciosList ?
                                                                    comerciosList.map((comercio: Comercio) => (
                                                                        <Tr key={comercio.id}>
                                                                            <Td textAlign={'center'}>
                                                                                <IconButton
                                                                                    onClick={() => handleComercioSelect(comercio)}
                                                                                    size={'sm'} mx={1}
                                                                                    colorScheme={comercioSelected === comercio.id ? 'telegram' : 'blackAlpha'}
                                                                                    aria-label={`select ${comercio.id}`}
                                                                                    icon={<FaPlus/>}/>
                                                                            </Td>
                                                                            <Td isNumeric>{comercio.cuit}</Td>
                                                                            <Td textOverflow={'ellipsis'}
                                                                                overflowX={'hidden'}
                                                                                maxWidth={'16rem'}>{
                                                                                comercio.nombre}
                                                                            </Td>
                                                                            <Td textOverflow={'ellipsis'}
                                                                                overflowX={'hidden'}
                                                                                maxWidth={'14rem'}>
                                                                                {comercio.contacto}
                                                                            </Td>
                                                                            <Td isNumeric>{comercio.telefono}</Td>
                                                                            <Td textOverflow={'ellipsis'}
                                                                                overflowX={'hidden'}
                                                                                maxWidth={'14rem'}>
                                                                                {comercio.direccion}
                                                                            </Td>
                                                                        </Tr>
                                                                    )) : []
                                                                }
                                                            </Tbody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TableSkeleton isLoading={isLoading}/>
                                                </AccordionPanel>
                                            </>
                                    }
                                </>
                            )}
                        </AccordionItem>

                        <AccordionItem>
                            {({isExpanded}) => (
                                <>
                                    <AccordionButton>
                                        <Box flex='1' textAlign='left'>
                                            <Box fontSize={'2xl'}>
                                                {socio.numeroDocumento ?
                                                    <Wrap>
                                                        <WrapItem>
                                                            {socio.numeroDocumento}
                                                        </WrapItem>
                                                        <WrapItem>
                                                            {socio.nombre + ' ' + socio.apellido}
                                                        </WrapItem>
                                                    </Wrap>
                                                    : 'Seleccione un socio'}
                                                {submitted &&
                                                (socio.numeroDocumento === undefined ||
                                                    socio.numeroDocumento === null ||
                                                    socio.numeroDocumento === 0) ? (
                                                    <Badge borderRadius={5} p={2} mx={2} mb={1} variant={'solid'}
                                                           colorScheme={'red'}>
                                                        <HStack px={2}>
                                                            <FaTimesCircle/>
                                                            <Box>
                                                                Debe buscar un socio!
                                                            </Box>
                                                        </HStack>
                                                    </Badge>
                                                ) : ([])}
                                            </Box>
                                        </Box>
                                        {isExpanded ? (
                                            <FaMinus fontSize='18px'/>
                                        ) : (
                                            <FaPlus fontSize='18px'/>
                                        )}
                                    </AccordionButton>
                                    <AccordionPanel>
                                        <Wrap justify={"space-around"}>
                                            <WrapItem>
                                                <FormControl isInvalid={
                                                    submitted &&
                                                    (socio.numeroDocumento === undefined ||
                                                        socio.numeroDocumento === null ||
                                                        socio.numeroDocumento === 0)}>

                                                    <FormLabel>Número de documento</FormLabel>
                                                    <NumberInput width={295}
                                                                 name={'socio.numeroDocumento'}
                                                                 value={form.socio ? form.socio.numeroDocumento : ''}
                                                    >
                                                        <NumberInputField

                                                            onChange={handleOnChangeDoc}
                                                            maxLength={8}
                                                            placeholder={"Ingrese un número de documento. ej: 33445566"}/>
                                                    </NumberInput>
                                                </FormControl>
                                            </WrapItem>
                                            <WrapItem>
                                                <Tooltip borderRadius={8} mr={2}
                                                         label={!isDocComplete ?
                                                             <HStack><FaInfoCircle fontSize={'55px'}/>
                                                                 <span>Debe ingresar un número de documento para buscar un socio
                                                        </span>
                                                             </HStack> : ''}
                                                         aria-label='searchTooltip'>
                                                    <Button
                                                        isLoading={isSearchingSocio}
                                                        type={'button'}
                                                        onClick={handleOnSearchSocio}
                                                        mt={8}
                                                        mb={4}
                                                        colorScheme={'orange'}
                                                        leftIcon={<FaSearch/>}>Buscar</Button>
                                                </Tooltip>
                                            </WrapItem>

                                        </Wrap>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                        <AccordionItem>
                            {({isExpanded}) => (
                                <>
                                    <AccordionButton>
                                        <Box flex='1' textAlign='left'>
                                            <Box fontSize={'2xl'}>
                                                Detalle de compra
                                                {submitted &&
                                                (errors.descripcion || errors.valor) ? (
                                                    <Badge borderRadius={5} p={2} mx={2} mb={1} variant={'solid'}
                                                           colorScheme={'red'}>
                                                        <HStack px={2}>
                                                            <FaTimesCircle/>
                                                            <Box>
                                                                Faltan cargar datos de la compra!

                                                            </Box>
                                                        </HStack>
                                                    </Badge>
                                                ) : ([])}
                                            </Box>
                                        </Box>
                                        {isExpanded ? (
                                            <FaMinus fontSize='18px'/>
                                        ) : (
                                            <FaPlus fontSize='18px'/>
                                        )}
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        <Wrap justify={"space-around"}>
                                            <WrapItem>
                                                <Wrap>
                                                    <WrapItem width={'100%'}>
                                                        <FormControl mx={1}
                                                                     isInvalid={submitted && errors.descripcion}
                                                                     isRequired>
                                                            <FormLabel>Descripcion</FormLabel>
                                                            <Input
                                                                w={'90%'}
                                                                name={'descripcion'}
                                                                placeholder={"Ingrese una descripción de la compra"}
                                                                type='text'
                                                                value={form.descripcion || []}
                                                                onChange={onInputChange}
                                                            />
                                                            {submitted && errors.descripcion ? (
                                                                <FormErrorMessage>{errors.descripcion}</FormErrorMessage>
                                                            ) : ([])}
                                                        </FormControl>
                                                    </WrapItem>
                                                    <WrapItem>
                                                        <FormControl mx={1}>
                                                            <FormLabel>Cantidad de cuotas</FormLabel>
                                                            <HStack>
                                                                <Wrap>
                                                                    {
                                                                        cantCuotas.map((value) =>
                                                                            <Button
                                                                                colorScheme={Number(quoteSelected) === value ? 'telegram' : 'gray'}
                                                                                key={value}
                                                                                value={value}
                                                                                onClick={handleOnChangeQuote}>{value}</Button>
                                                                        )
                                                                    }
                                                                </Wrap>
                                                            </HStack>
                                                        </FormControl>

                                                    </WrapItem>
                                                </Wrap>
                                            </WrapItem>
                                            <WrapItem>

                                                <Wrap>
                                                    <WrapItem width={'80%'}>
                                                        <FormControl mx={1} isInvalid={submitted && errors.fecha}>
                                                            <FormLabel>Fecha de compra</FormLabel>
                                                            <Input
                                                                readOnly={isComercio(rol)}
                                                                name={'fecha'}
                                                                type='datetime-local'
                                                                value={form.fecha ? form.fecha : today}
                                                                onChange={onInputChange}
                                                            />
                                                            {submitted && errors.fecha ? (
                                                                <FormErrorMessage>{errors.fecha}</FormErrorMessage>
                                                            ) : ([])}
                                                        </FormControl>
                                                    </WrapItem>
                                                    <WrapItem width={'80%'}>
                                                        <FormControl mx={1} isInvalid={submitted && errors.valor}
                                                                     isRequired>
                                                            <FormLabel>Monto de la compra</FormLabel>
                                                            <NumberInput
                                                                min={0}
                                                                onChange={handleOnChangeValor}
                                                                value={montoFormat(form.valor ? form.valor : '')}
                                                                precision={2}
                                                                name={'valor'}
                                                            >
                                                                <NumberInputField onChange={onInputChange}
                                                                                  placeholder={"Ingrese un monto de la compra. ej: $5233.45"}/>
                                                            </NumberInput>
                                                            {submitted && errors.valor ? (
                                                                <FormErrorMessage>{errors.valor}</FormErrorMessage>
                                                            ) : ([])}
                                                        </FormControl>
                                                    </WrapItem>
                                                    {
                                                        isComercio(rol) ?
                                                            []
                                                            :
                                                            <WrapItem>
                                                                <FormControl display='flex' alignItems='center'>
                                                                    <FormLabel htmlFor={'switchCommission'}>Agregar
                                                                        comisión</FormLabel>
                                                                    <Switch id={'switchCommission'}
                                                                            name={'hasCommission'}
                                                                            colorScheme={'blue'}
                                                                            defaultChecked={true}
                                                                            isChecked={form.hasCommission}
                                                                            onChange={onInputChange}
                                                                    />
                                                                </FormControl>
                                                            </WrapItem>
                                                    }
                                                </Wrap>
                                            </WrapItem>
                                        </Wrap>


                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    </Accordion>

                </Stack>
                <HStack justify={'flex-end'}>
                    <Button onClick={sendCode}
                            m={4} my={6} mr={0}
                            type={"button"}
                            leftIcon={<FaKey/>}
                            colorScheme={'blue'}>
                        Validar compra
                    </Button>
                </HStack>
            </form>

            <CodeModal showModal={showCodeModal}
                       onCloseModal={handleCloseModal}
                       id={socio.id}
                       isSaving={isSaving}
                       handleConfirm={onSubmit}
                       codeTemp={code}
            />
        </>
    )
}
