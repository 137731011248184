export const validations = {
    nombre: {
        required: {
            value: true,
            message: "Debe ingresar un nombre",
        },
        pattern: {
            value: /^[ña-zÑA-Z\s]*$/,
            message: "El nombre solo debe contener letras",
        },
    },
    apellido: {
        required: {
            value: true,
            message: "Debe ingresar un apellido",
        },
        pattern: {
            value: /^[ña-zÑA-Z\s]*$/,
            message: "El apellido solo debe contener letras",
        },
    },
    numeroDocumento: {
        required: {
            value: true,
            message: "Debe ingresar un número de documento",
        },
        custom: {
            isValid: (value: any) => value?.length >= 8,
            message: "Debe ingresar al menos 8 dígitos",
        },
    },
    fechaAlta: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de alta",
        },
    },
    telefono: {
        required: {
            value: true,
            message: "Debe ingresar un teléfono",
        },
        custom: {
            isValid: (value: any) => value?.length >= 6,
            message: "Debe ingresar al menos 6 dígitos",
        },
    },
    direccion: {
        required: {
            value: true,
            message: "Debe ingresar una dirección",
        },
    },
    localidad: {
        required: {
            value: true,
            message: "Debe seleccionar una localidad",
        },
    },
    fechaNacimiento: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de nacimiento",
        },
    },
    cuil: {
        required: {
            value: true,
            message: "Debe ingresar un cuil",
        },
        custom: {
            isValid: (value: any) => value?.length >= 11,
            message: "Debe ingresar al menos 11 dígitos",
        },
    },
    estadoCivil: {
        required: {
            value: true,
            message: "Debe seleccionar un estado civil",
        },
    },
    tipo: {
        required: {
            value: true,
            message: "Debe seleccionar una clase",
        },
    },
    correo: {
        required: {
            value: true,
            message: "Debe ingresar un correo",
        },
        pattern: {
            value: /[ña-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[ña-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?\.)+[ña-z0-9](?:[ña-z0-9-]*[ña-z0-9])?/,
            message: "El correo debe tener el formato 'alias@mail.com'",
        },
    },
    legajo: {
        required: {
            value: true,
            message: "Debe ingresar un legajo",
        },
    },
    fechaIngresoLaboral: {
        required: {
            value: true,
            message: "Debe ingresar una fecha de ingreso",
        },
    },
    numeroCuenta: {
        custom: {
            isValid: (value: any) => value === undefined || value === '' || value?.length >= 6,
            message: "Debe ingresar al menos 6 dígitos",
        },
    },
    cbu: {
        custom: {
            isValid: (value: any) => value === undefined || value === '' || value?.length === 22,
            message: "Debe ingresar 22 dígitos - faltan: ",
        },
    }
}