import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Button
} from "@chakra-ui/react";

export const DeleteConfirm = ({isOpen, onClose, pron, entity, id, description, onDeleteConfirm, cancelRef}: any) => {

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                size={'lg'}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Eliminar {entity}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro que desea eliminar {pron} {entity} &nbsp;
                            <Badge variant={'subtle'}
                                   colorScheme={'red'}>
                                {description}
                            </Badge> ?

                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button colorScheme='red' onClick={() => onDeleteConfirm(id)} ml={3}>
                                Eliminar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}