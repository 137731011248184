import {Button, Divider, Heading, Input, InputGroup, InputLeftElement, Wrap, WrapItem} from "@chakra-ui/react";
import {FaFileInvoiceDollar, FaSearch} from "react-icons/fa";
import {useDownloadFile} from "../../../hooks/useDownloadFile";


export const CustomDescuentosHeading = ({
                                            title = '',
                                            findParam = [] as any,
                                            month = '',
                                            data = [],
                                            onChangeMonth = [] as any,
                                            handleDownload = [] as any,
                                            isDownloading = false,
                                        }) => {
    return (
        <>
            <Wrap justify={'space-between'}>
                <WrapItem>
                    <Heading size='lg'>{title}</Heading>
                </WrapItem>

                <WrapItem>
                    <Wrap>
                        <WrapItem>
                            <InputGroup>
                                <Input
                                    type={'month'}
                                    value={month}
                                    onChange={onChangeMonth}
                                />
                            </InputGroup>
                        </WrapItem>
                        <WrapItem>
                            <InputGroup zIndex={0}>
                                <InputLeftElement
                                    pointerEvents='none'
                                    children={<FaSearch color={'lightgray'}/>}
                                />
                                <Input placeholder={'Buscar'} onChange={findParam}/>
                            </InputGroup>
                        </WrapItem>
                        <WrapItem>
                            <Button px={12}
                                    me={2}
                                    isLoading={isDownloading}
                                    disabled={data ? data.length === 0 : true}
                                    onClick={handleDownload}
                                    colorScheme={'red'}
                                    variant={'solid'} aria-label={'download txt'}
                                    leftIcon={<FaFileInvoiceDollar/>}>Débitos</Button>
                        </WrapItem>
                    </Wrap>
                </WrapItem>

            </Wrap>
            <Divider
                my={5}
            />
        </>
    )
}