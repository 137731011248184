
const valueFormat = (sign: string, val: any) => sign + val

export const getMin = (type: string) => {
    if (isClosingDate(type) || isComision(type)) {
        return 1
    }

    return 1000
}

export const getMax = (type: string) => {
    if (isClosingDate(type)) {
        return 31
    }

    if (isComision(type)) {
        return 100
    }

    return 200000
}

export const getPrecision = (type: string) => {
    if (isClosingDate(type) || isComision(type)) {
        return 0
    }

    return 2
}

export const getFormat = (type: string, value: any) => {
    if (isClosingDate(type)) {
        return value
    }

    if (isComision(type)) {

        return Number(value) + '%'
    }


    return valueFormat("$", value)
}

export const isClosingDate = (type: string) => {
    return type === 'DiaCierreMes' || type === 'DÍA DE CIERRE';
}

export const isComision = (type: string) => {
    return type === 'Comision' || type === 'COMISIÓN';
}


export const parseValue = (type: string, value: any) => {

    if (isClosingDate(type) || isComision(type)) {
        return getFormat(type, value)
    }

    return getFormat(type, Number(value).toFixed(2))
}